import React, { useState, useRef, useEffect, useContext} from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Accordion, Button, Form, Modal} from 'react-bootstrap';
import {useOpenAI}  from '../openai/openai_context';
import { FormContext } from '../homeOwner/context/form_context';

import Layout from '../layout';
import '../../styles/homeOwner/virtual_walkthrough.css';
import ProcessWalkthrough from './process_walkthrough';
import { DetectOpenAI } from '../openai/openai_util';
import Trash from '../../assets/lexos_imgs/icons/trash.png';
import NavigationArrows from '../layout/nav_arrows';
import { MdCameraEnhance, MdDriveFolderUpload } from 'react-icons/md';


const VirtualWalkthrough = () => {
  const navigate = useNavigate();
  const { setOpenAIGenerator } = useOpenAI();


  const handleBack = () => {
    navigate('/begin-tour', { state: location.state });
  };

  /* State variables for managing photos, processing, and results */
  // const [roomResults, setRoomResults] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [activeKey, setActiveKey] = useState('0');
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const fileInputRefs = useRef({});
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const { formData, setFormData } = useContext(FormContext);
  const photos = formData.photos || {};
  const { bedrooms, floors, exactBedrooms, exactFloors } = location.state || {};

  useEffect(() => {
    // Detect if the user is on a mobile device
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkIfMobile();
  }, []);


  /* ****** Room Objects (Accordions) and their functionalities ********
  *
  *
  */
  const [rooms, setRooms] = useState(() => {
    const initialRooms = [];
  
    const bedroomCount = bedrooms === '4+' ? parseInt(exactBedrooms) : parseInt(bedrooms);
    // Logic to generate default rooms based on bedrooms
    if (bedrooms === 'Studio') {
      initialRooms.push({ id: '1', name: 'Studio' });
    } else if (bedrooms === '1') {
      initialRooms.push({ id: '1', name: 'Bedroom' }); // No "1" label if it's the only bedroom
    } else {
      for (let i = 1; i <= bedroomCount; i++) {
        initialRooms.push({ id: `${i}`, name: `Bedroom #${i}` });
      }
    }

    const floorCount = floors === '4+' ? parseInt(exactFloors) : parseInt(floors);
  
    // Add logic for floors or other rooms
    if (floorCount >= 2) {
      initialRooms.push({ id: `${initialRooms.length + 1}`, name: 'Living Room' });
      initialRooms.push({ id: `${initialRooms.length + 1}`, name: 'Kitchen' });
      if (floorCount >= 3) {
        initialRooms.push({ id: `${initialRooms.length + 1}`, name: 'Basement' });
      }
    }
  
    return initialRooms;
  });

  const handleAccordionChange = (key) => {
    setActiveKey(key);
  };

  const handleFileUpload = (roomId, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setFormData(prevData => {
        const prevPhotos = prevData.photos || {};
        return {
          ...prevData,
          photos: {
            ...prevPhotos,
            [roomId]: [...(prevPhotos[roomId] || []), ...Array.from(files)],
          },
        };
      });
    }
  };

  const handleTakePhoto = (roomId) => {
    // Check if the device has media capture capabilities
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          // Create a video element to show the camera feed
          const video = document.createElement('video');
          video.srcObject = stream;
          video.setAttribute('playsinline', ''); // Important for iOS to avoid fullscreen video
          video.play();
  
          // Create a canvas element to capture the photo
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
  
          // Append the video element to the DOM (or a specific container)
          document.body.appendChild(video);
  
          // Listen for a user interaction (e.g., button click) to take the photo
          video.addEventListener('click', () => {
            // Set canvas dimensions to match the video feed
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
  
            // Draw the current video frame to the canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            // Stop the video stream (camera)
            stream.getTracks().forEach(track => track.stop());
  
            // Get the image data as a blob
            canvas.toBlob((blob) => {
              // Update the state with the new photo
              // setPhotos((prevPhotos) => ({
              //   ...prevPhotos,
              //   [roomId]: [...(prevPhotos[roomId] || []), blob],
              // }));
              setActiveKey(String(parseInt(activeKey) + 1));
            }, 'image/jpeg');
  
            // Remove the video element from the DOM after capturing the photo
            document.body.removeChild(video);
          });
        })
        .catch((err) => {
          console.error("Error accessing camera:", err);
          alert("Could not access your camera. Please ensure you have granted the necessary permissions");
        });
    } else {
      console.error("Media Devices API not supported on this browser.");
      alert("Your device does not support camera access.");
    }
  };
  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeletePhoto = (roomId, index) => {
    setFormData(prevData => {
      const prevPhotos = prevData.photos || {};
      return {
        ...prevData,
        photos: {
          ...prevPhotos,
          [roomId]: prevPhotos[roomId].filter((_, i) => i !== index),
        },
      };
    });
  };

  /* ******************************************************************* */

  /* ********* Submitting the walkthrough and handling the response ********
  *
  *
  * 
  * 
  * 
  */ 
  const handleSubmit = async () => {
    setIsProcessing(true);
    const photosByRoom = rooms.reduce((acc, room) => {
      if (photos[room.id] && photos[room.id].length > 0) {
        acc[room.name] = photos[room.id];
      }
      return acc;
    }, {});

    if (Object.keys(photosByRoom).length > 0) {
      const detectOpenAI = DetectOpenAI(photosByRoom);
      const firstResult = await detectOpenAI.next();

      if (!firstResult.done && !firstResult.value.error) {
        setOpenAIGenerator(detectOpenAI); // Store the generator in the context
        navigate('/generate-inventory', { 
          state: {
            // Pass the property information 
            ...location.state, 
            initialResult: firstResult.value, // Pass only the first room's result
            photosByRoom, // Pass the photos for further processing
          } 
        });
      } else {
        console.error('Error processing the first room:', firstResult.value.error || 'Unknown error');
        setIsProcessing(false);
      }
    } else {
      alert("Please upload photos for at least one room before submitting.");
      setIsProcessing(false);
    }
  };

  
  const handleProcessingComplete = (result) => {
    setIsProcessing(false);
    console.log("Processing complete");
    if (analysisResult) {
      navigate('/generate-inventory', { state: { result: analysisResult } });
    } else {
      console.error("No analysis result available");
      alert("An error occurred while processing your photos. Please try again.");
    }
  };
  /* Logic to add, remove, adjust rooms 
  *
  *
  */
  const addRoom = () => {
    const newRoomId = String(rooms.length + 1);
    setRooms([...rooms, { id: newRoomId, name: `Room #${newRoomId}` }]);
  };
  
  const updateRoomName = (id, newName) => {
    setRooms(rooms.map(room => 
      room.id === id ? { ...room, name: newName } : room
    ));
  };
  
  const deleteRoom = (id) => {
    setRooms(rooms.filter(room => room.id !== id));
    setFormData(prevData => {
      const prevPhotos = prevData.photos || {};
      const newPhotos = { ...prevPhotos };
      delete newPhotos[id];
      return {
        ...prevData,
        photos: newPhotos,
      };
    });
  };

  return (
    <Layout>
      <div className="walkthrough-container">
        <h1>Virtual Walkthrough</h1>
        <p className= "capture-desc">Please upload or capture photos for each room in your home</p>
        <Accordion activeKey={activeKey} onSelect={handleAccordionChange} className="styled-accordion">
        {rooms.map((room, index) => (
          <Accordion.Item key={room.id} eventKey={String(index)}>
            <Accordion.Header>
              <input
                type="text"
                value={room.name}
                onChange={(e) => updateRoomName(room.id, e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className="room-name-input"
                placeholder="Enter room name"
              />
              <button className="delete-room-btn" onClick={() => deleteRoom(room.id)}>
                <img src={Trash} alt="Delete" className="trash-icon" />
              </button>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group>
                <Form.Label>Upload photos for your {room.name}:</Form.Label>
                <div className="button-group">
                  <Button                       
                    variant="primary"
                    onClick={() => handleTakePhoto(room.id)}
                    disabled={!isMobile}  // Disable button if not mobile
                  >
                  <MdCameraEnhance size={24} style={{ marginRight: '8px' }}/> Take Photos
                  </Button>
                  <Button variant="secondary" onClick={() => fileInputRefs.current[room.id]?.click()} className="me-2">
                    <MdDriveFolderUpload size={24}  style={{ marginRight: '8px' }}/>
                    Upload Photos
                  </Button>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleFileUpload(room.id, e)}
                    style={{ display: 'none' }}
                    ref={(el) => {
                      if (el) fileInputRefs.current[room.id] = el;
                    }}
                  />
                </div>
                {photos[room.id] && photos[room.id].length > 0 && (
                  <div className="photo-previews">
                    {photos[room.id].map((photo, idx) => (
                      <div key={idx} className="photo-container">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt={`Preview ${idx + 1}`}
                          className="photo-preview"
                          onClick={() => handlePhotoClick(URL.createObjectURL(photo))}
                          style={{ cursor: 'pointer' }}
                        />
                        <button className="delete-photo-btn" onClick={() => handleDeletePhoto(room.id, idx)}>
                            <img src={Trash} alt="Delete" className="delete-photo-icon" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <div className="add-room-container">
          <Button onClick={addRoom} className="add-room-btn">
            + Add Room
          </Button>
        </div>
        <div className="virtual-walkthrough-nav">
        <NavigationArrows
                onPrevClick={handleBack}
                onNextClick={handleSubmit}
                prevLabel={'Previous'}
                nextLabel={"Submit Walkthrough"}
        />
      </div>
      </div>

      <ProcessWalkthrough 
        isVisible={isProcessing} 
        onComplete={handleProcessingComplete} 
        analysisResult={analysisResult}
        photosByRoom={photos}
      />

      {/* Modal for displaying full image */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedPhoto} alt="Full Size" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default VirtualWalkthrough;
