import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout.js';
import '../../styles/movingCos/submit_offer.css';
import ExtendOffer from '../../assets/lexos_imgs/offer-extend.png';

const SubmitOffer = () => {
    const [formState, setFormState] = useState({
        quoteAmount: '',
        insuranceCoverage: '',
        estimateType: '',
        moveDuration: '',
        termsAccepted: false,
        file:null,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [offerSubmitted, setOfferSubmitted] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFileChange = (event) => {
        setFormState({
            ...formState,
            file: event.target.files[0],
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        // Simulate API call
        setTimeout(() => {
            setIsSubmitting(false);
            setOfferSubmitted(true);
        }, 2000);
    };

    const navigate = useNavigate();

    
    return (
        <Layout>
            <div className="submitOffer-container">
                <h1 className="form-title">Submit Your Offer</h1>
                <img src={ExtendOffer} alt="Extend Offer" className="extend-offer-img" />
                {offerSubmitted ? (
                    <div className="offer-submitted">
                        <h2>Offer Submitted</h2>
                        <p>We have notified the user of your offer.</p>
                    </div>
                ) : (
                    <form className="offer-form" onSubmit={handleSubmit}>
                        <label htmlFor="quoteAmount">Quote Amount:</label>
                        <input
                            type="number"
                            id="quoteAmount"
                            name="quoteAmount"
                            value={formState.quoteAmount}
                            onChange={handleChange}
                            required
                        />

                        <p>Are you providing insurance coverage?</p>
                        <label>
                            <input
                                type="radio"
                                name="insuranceCoverage"
                                value="yes"
                                checked={formState.insuranceCoverage === 'yes'}
                                onChange={handleChange}
                            />
                            Yes
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="insuranceCoverage"
                                value="no"
                                checked={formState.insuranceCoverage === 'no'}
                                onChange={handleChange}
                            />
                            No
                        </label>

                        <p>Is the quote you're submitting a binding or non-binding estimate?</p>
                        <label>
                            <input
                                type="radio"
                                name="estimateType"
                                value="binding"
                                checked={formState.estimateType === 'binding'}
                                onChange={handleChange}
                            />
                            Binding
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="estimateType"
                                value="non-binding"
                                checked={formState.estimateType === 'non-binding'}
                                onChange={handleChange}
                            />
                            Non-binding
                        </label>

                        <label htmlFor="moveDuration">How long do you think the move will take:</label>
                        <input
                        type="text"
                        id="moveDuration"
                        name="moveDuration"
                        value={formState.moveDuration}
                        onChange={handleChange}
                        required
                        />

                        <div className="file-upload-container">
                            <input
                                type="file"
                                id="fileUpload"
                                name="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="fileUpload" className="file-upload-label">Upload Cost Breakdown File </label>
                            <p className="file-upload-description">Your customer will see any attachments you'd like to attach here</p>
                        </div>

                        <div className="terms-container">
                            <input
                                type="checkbox"
                                name="termsAccepted"
                                checked={formState.termsAccepted}
                                onChange={handleChange}
                                required
                            />
                            <label>I agree to the terms and services</label>
                        </div>

                        <div className="buttons-container">
                            <button type="button" className="bid-back-button" onClick={() => navigate('/listings')}>Back</button>
                            <button type="submit" className="bid-submit-button">
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </Layout>
    );
};

export default SubmitOffer;