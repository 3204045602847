import React, {useEffect} from 'react';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import '../../styles/homeOwner/inventory-dest.css';
import Trash from '../../assets/lexos_imgs/icons/trash.png';

const DestinationComponent = ({ destinations, setDestinations }) => {
    useEffect(() => {
    // If there are no destinations, add an empty one
        if (destinations.length === 0) {
        setDestinations(['']);
        }
    }, []);
  const handleAddressChange = (index, place) => {
    const newDestinations = [...destinations];
    newDestinations[index] = place.formatted_address;
    setDestinations(newDestinations);
  };

  const addDestination = () => {
    setDestinations([...destinations, '']);
  };

  const removeDestination = (index) => {
    const newDestinations = destinations.filter((_, i) => i !== index);
    setDestinations(newDestinations);
  };

  return (
    <div className="destination-component">
      {destinations.map((dest, index) => (
        <div key={index} className="destination-input">
          {!dest ? (
            <div className="search-bar">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => handleAddressChange(index, place)}
                options={{
                  types: ['address'],
                  componentRestrictions: { country: 'us' }
                }}
                className="search-input"
                placeholder={index === 0 ? "Search new home address..." : "Search for address..."}
              />
            </div>
          ) : (
            <div className="selected-address">
              <span>{dest}</span>
              <button
                onClick={() => removeDestination(index)}
                className="delete-icon"
              >
                <img src={Trash} alt="Delete" />
              </button>
            </div>
          )}
        </div>
      ))}

      <button
        onClick={addDestination}
        className="btn-add-destination"
      >
        + Add Another Destination
      </button>
        <div className = "note-container">
              <div className="note">
                Note: If you wish to store any inventory outside of the moving company’s storage, input the address(es) above 
              </div>
        </div>
    </div>
  );
};

export default DestinationComponent;