import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Initialize Firestore
const db = getFirestore();
const auth = getAuth();

export const getUserInfo = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, 'homeOwners', user.uid); // Assuming userId is the document ID
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      console.error('No such document in homeOwners!');
      return null;
    }
  } else {
    console.error('No user is logged in');
    return null;
  }
};

/**
 * Generate a custom document ID based on username and document count
 * @param {string} collectionName - Name of the collection
 * @param {string} username - Username of the current user
 * @param {string} prefix - Prefix for the document ID (e.g., 'origin', 'dest')
 * @returns {string} - Generated document ID
 */
const generateDocumentId = async (collectionName, username, prefix) => {
  const user = auth.currentUser;
  if (user) {
    const colRef = collection(db, collectionName);
    const q = query(colRef, where('userId', '==', user.uid));
    const snapshot = await getCountFromServer(q);
    const count = snapshot.data().count + 1; // Increment count for new document
    return `${username}-${prefix}-${count}`;
  }
  return null;
};

/**
 * Save Origin Information to Firestore
 * @param {Object} originInfo - The origin information to be stored
 */
export const saveOriginInfo = async (originInfo) => {
  const user = auth.currentUser;
  const userInfo = await getUserInfo();
  if (user && userInfo) {
    const documentId = await generateDocumentId('originInfo', userInfo.username, 'origin');
    const originDoc = doc(db, 'originInfo', documentId);
    await setDoc(originDoc, {
      ...originInfo,
      userId: user.uid,
      propertyInfoId: documentId,
      created_at: new Date(),
    }, { merge: true });
  }
};

/**
 * Save Destination Information to Firestore
 * @param {Object} destinationInfo - The destination information to be stored
 * @param {string} documentId - Document ID for the destination
 */
export const saveDestinationInfo = async (destinationInfo, documentId) => {
  const user = auth.currentUser;
  if (user) {
    const destinationDoc = doc(db, 'destinationInfo', documentId);
    await setDoc(destinationDoc, {
      ...destinationInfo,
      userId: user.uid,
      destinationId: documentId,
      created_at: new Date(),
    }, { merge: true });
  }
};

/**
 * Save Move Details to Firestore
 * @param {Object} moveDetails - The move details to be stored
 */
export const saveMoveDetails = async (moveDetails) => {
  const user = auth.currentUser;
  const userInfo = await getUserInfo();
  if (user && userInfo) {
    const documentId = await generateDocumentId('moveDetails', userInfo.username, 'movedets');
    const moveDetailsDoc = doc(db, 'moveDetails', documentId);
    await setDoc(moveDetailsDoc, {
      ...moveDetails,
      userId: user.uid,
      moveDetailsId: documentId,
      created_at: new Date(),
    }, { merge: true });
  }
};

/**
 * Save Virtual Walkthroughs to Firestore
 * @param {Object} virtualWalkthrough - The virtual walkthrough data to be stored
 */
export const saveVirtualWalkthrough = async (virtualWalkthrough) => {
  const user = auth.currentUser;
  const userInfo = await getUserInfo();
  if (user && userInfo) {
    const documentId = await generateDocumentId('virtualWalkthroughs', userInfo.username, 'walk');
    const virtualWalkDoc = doc(db, 'virtualWalkthroughs', documentId);
    await setDoc(virtualWalkDoc, {
      ...virtualWalkthrough,
      userId: user.uid,
      virtualWalkId: documentId,
      created_at: new Date(),
    }, { merge: true });
  }
};

/**
 * Save Inventory to Firestore
 * @param {Object} inventory - The inventory data to be stored
 */
export const saveInventory = async (inventory) => {
  const user = auth.currentUser;
  const userInfo = await getUserInfo();
  if (user && userInfo) {
    const documentId = await generateDocumentId('Inventory', userInfo.username, 'inventory');
    const inventoryDoc = doc(db, 'Inventory', documentId);
    await setDoc(inventoryDoc, {
      ...inventory,
      userId: user.uid,
      inventoryId: documentId,
      created_at: new Date(),
    }, { merge: true });
  }
};