import React, {useState} from "react";
import Layout from '../layout';
import { useNavigate, useLocation } from 'react-router-dom';
import Selector from '../../assets/lexos_imgs/best_selection.png';
import '../../styles/homeOwner/dashboard.css';
import PinkZebra from '../../assets/lexos_imgs/pink-zebra.png';
import MoveMate from '../../assets/lexos_imgs/ove_mate.jpg';
import BestRate from '../../assets/lexos_imgs/best_rate.png';
import OfferModal from './offer_modal';
import Relax from '../../assets/lexos_imgs/mirror_relax.png';

const Dashboard = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState("");
  
    const handleViewOffer = (offer) => {
      setSelectedOffer(offer);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
    const navigate = useNavigate();

    const handleInventoryClick = () => {
        navigate('/generate-inventory'); // This will navigate to the inventory page
};

    const handleProfileClick = () => {
      navigate('/company-profile'); // This will navigate to the company profile page
    };
  return (
    <Layout> 
    <div className = "dashboard-container">
        <div className="container px-4 px-lg-5">
        {/* Heading Row */}
        <div className="row gx-4 gx-lg-5 align-items-center my-5">
            <div className="col-lg-7">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={Selector}
                alt="Offer Banner"
            />
            </div>
            <div className="col-lg-5">
            <h1 className="font-weight-light">My Offers</h1>
            <p>
                Moving companies will provide you with offers based on the property information you provided.
                They will include the quote amount, insurance coverage (if applicable), estimate type, move duration, and other relavant information. 
                
            </p>
            <button className="btn btn-inventory" onClick = {handleInventoryClick} disabled>View My Inventory</button>
            <p className= "view-soon"> Coming Soon!</p>
            </div>
        </div>

        {/* Call to Action */}
        <div className="card text-white bg-secondary my-5 py-4 text-center">
            <div className="card-body">
            <h3>
                " ...the easiest move I've ever had. I was able to compare and select the best offer in my price range." 
            </h3>
            <p> - Satisifed Customer</p>
            </div>
        </div>

        {/* Content Row */}
        {/* No Offers Yet Section */}
        <div className="no-offers-section">
            <hr className="offers-hr-left" />
            <h2 className="no-offers-text">No Offers Yet!</h2>
            <hr className="offers-hr-right" />
            <img src={Relax} alt = "Relax" className="relax-img" />
            <p className="offers-info">Offers will show up here when moving companies view your move details</p>
        </div>
        {/* Fake offers Section */}
        {/* <div className="row gx-4 gx-lg-5">
            <div className="col-md-4 mb-5">
            <div className="card h-100">
                <div className="card-body">
                <img 
                    src={PinkZebra} 
                    alt="Profile" 
                    className="profile-img" 
                    onClick={handleProfileClick} // Navigate to profile on image click
                    style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
                  />
                <h2 className="card-title">Pink Zebra Moving</h2>
                <p className="card-text">
                    Estimate: $2340
                    ...
                </p>
                </div>
                <div className="card-footer">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleViewOffer('Pink Zebra Moving')}>View Offer</button>
                    <button className="btn btn-primary btn-sm">Accept Offer</button>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-5">
            <div className="card h-100">
                <div className="card-body">
                <img src={MoveMate} alt="Profile" className="profile-img" />
                <h2 className="card-title">Move Mate Boston</h2>
                <p className="card-text">
                    Estimate: $3120
                    ...
                </p>
                </div>
                <div className="card-footer">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleViewOffer('Move Mate Boston')}>View Offer</button>
                    <button className="btn btn-primary btn-sm">Accept Offer</button>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-5">
            <div className="card h-100">
                <div className="card-body">
                <img src={BestRate} alt="Profile" className="profile-img" />
                <h2 className="card-title">Boston Best Rate Movers</h2>
                <p className="card-text">
                    Estimate: $2860
                    ...
                </p>
                </div>
                <div className="card-footer">
                    <button className="btn btn-secondary btn-sm" onClick={() => handleViewOffer('Boston Best Rate Movers')}>View Offer</button>
                    <button className="btn btn-primary btn-sm">Accept Offer</button>
                </div>
            </div>
            </div>
        </div>*/}
        </div>
    </div> 
    <OfferModal
        show={showModal}
        onClose={handleCloseModal}
        offerDetails={selectedOffer}
      />
    </Layout>
  );
};

export default Dashboard;