import styled, { keyframes, css } from 'styled-components';
import CopyComponent from '../../copy';
import WhiteIcon from '../../../assets/lexos_imgs/logos/white_icon_only.png';

/* ***Logo Spinning Animation*** 
* Keyframes
* SpinningLogo
*
*/
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const TypingContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TypingCursor = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(${WhiteIcon});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${blink} 0.7s infinite;
  filter: brightness(0) saturate(100%) invert(34%) sepia(77%) saturate(1422%) hue-rotate(233deg) brightness(94%) contrast(93%);
  vertical-align: middle;
  margin-left: 2px;
`;
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;



/* ********** Inventory Content Styles ********** 
* 
* 
* 
* 
*/

export const InventoryTitle = styled.h1`
  color: #fff;
  text-align: center;
  margin-top: 50px;
`;
export const InventoryCheckbox = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
padding-top: 10px;
color: #8b50f7;

input[type='checkbox'] {
  margin-bottom: 10px;
}
`;
export const StyledInventoryBox = styled.div`
  padding: 20px;
  border: 1px solid #8b50f7;
  border-radius: 10px;
  background-color: #f8f8ff;
  margin: 20px auto;
  width: 80%;
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  font-weight: bold;
  color: #8b50f7;
  padding-bottom: 15px;
  border-bottom: 1px solid #8b50f7;
  margin-bottom: 20px;
  text-align: center;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
`;
export const ContentArea = styled.div`
  max-height: calc(70vh - 100px);
  overflow-y: auto;
  padding-right: 5px;
`;
export const ScrollableRows = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  border-bottom: 2px solid #8b50f7;
  padding: 10px 0;
  color: #8b50f7;
`;
export const ItemColumn = styled.div`
  padding-right: 20px;
`;

export const RoomTitleRow = styled(ItemRow)`
  background-color: #f0f0ff;
  font-weight: bold;
`;

export const RoomTitle = styled.h2`
  color: #8b50f7;
`;


export const Column = styled.div`
  padding-right: 20px;
`;

export const ServiceColumn = styled.div`
  border-left: 2px solid #8b50f7;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
 
`;

export const HandledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  border-left: 2px solid #8b50f7;
  padding-left: 20px;
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
`;

export const InventoryContent = styled.div`
  color: #4A4860;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  strong {
    display: block;
    margin-top: 10px;
  }

  .item-details {
    margin-left: 20px;
  }
`;

export const EditButton = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #8b50f7;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7226f5;
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #4A4860;
`;
export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #8b50f7;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: #8b50f7;
    
    &::after {
      content: '✓';
      font-size: 16px;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const SubCheckbox = styled(CheckboxLabel)`
  margin-left: 30px;
  font-size: 12px;
`;

export const EstimatedCostInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  left: 30px;
`;

export const DollarSign = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #4A4860;
`;

export const EstimatedCostInput = styled.input`
  padding: 10px 0px 10px 17.5px; /* Adjust padding to account for the dollar sign */
  border: 3px solid #8b50f7;
  border-radius: 8px;
  font-size: 14px;
  width: 150px;
  color: #4A4860;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(139, 80, 247, 0.3);
  }
  
  &::placeholder {
    color: #aaa;
  }
`;


/* ********** Accordion Styles **********
*
*
*
*
*/
export const AccordionSection = styled.div`
  width: 80%;
  margin: 20px auto;
`;

export const AccordionItem = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const AccordionButton = styled.button`
  background-color: #fff;
  color: #8b50f7;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:focus {
    background-color: #e0d4fd;
    box-shadow: 0 0 0 0.25px #8b50f7;
  }
`;

export const AccordionContent = styled.div`
  padding: 10px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;


/* ********** File Input Button Styles **********
*
*
*
*
*/
export const FileInputButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: #8b50f7;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #7226f5;
  }

  & input {
    display: none;
  }

  & svg {
    margin-right: 10px;
  }
`;

export const AnimatedItemRow = styled(ItemRow)`
  animation: ${fadeIn} 0.5s ease-in;
`;

export const AnimatedCheckbox = styled(StyledCheckbox)`
  animation: ${fadeIn} 0.5s ease-in;
`;

export const RoomTitleWithCursor = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomTitleText = styled.h1`
  margin-right: 2px;
  color: #8b50f7;
`;