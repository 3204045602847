import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../firebase/fireconfig";
import '../../../styles/homeOwner/register/forgot.css';
import Layout from '../../layout';
import Forgot from '../../../assets/lexos_imgs/forgot.png';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Function to validate the email
    const isValidEmail = (email) => {
        return /^\S+@\S+\.\S+$/.test(email);
    }
    
    const handleReset = async () => {
      try {
        await sendPasswordResetEmail(auth, email);
        setMessage("Reset link sent to your email.");
      } catch (error) {
        setMessage(error.message);
      }
    }

    const handleBack = () => {
        navigate('/login');
    }
  
    return (
     <Layout> 
      <div className="reset-container">
        <h2>Reset Password</h2>
        <img src={Forgot} className="forgot-img" alt="forgot" />
        <input 
          type="email"
          placeholder="Enter your email to reset your password"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* Button will be disabled if email is invalid */}
        <button 
          onClick={handleReset}
          disabled={!isValidEmail(email)}
          style={{
            backgroundColor: isValidEmail(email) ? 'var(--primary-color)' : 'grey',
            cursor: isValidEmail(email) ? 'pointer' : 'not-allowed'
          }}
          className="reset-button"
        >
          Send Reset Link
        </button>
        {message && <p className="sent-link-msg">{message}</p>}
        <button className="back-button" onClick={() => navigate('/login')}>
        <i className="fa fa-arrow-left"></i>
        Back
        </button>
      </div>
      </Layout>
    );
  }

  export default ForgotPassword;
