import React, { useState, useEffect, useContext, useRef } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import DestinationSelectionPopup from './storage-popup';
import Layout from '../layout';
import {useOpenAI}  from '../openai/openai_context';
import 'react-quill/dist/quill.snow.css';
import CopyComponent from '../copy';
import '../../styles/homeOwner/generate_inventory.css';
import { FormContext } from './context/form_context';
import Trash from '../../assets/lexos_imgs/icons/trash.png';
import InventoryIcon from '../../assets/lexos_imgs/inventory.png';
import Item from '../../assets/lexos_imgs/icons/items.png';
import Trolly from '../../assets/lexos_imgs/icons/trolly.png';
import Value from '../../assets/lexos_imgs/icons/handle-with-care.png';
import NavigationArrows from '../layout/nav_arrows';

import {
  InventoryTitle,
  StyledInventoryBox,
  HeaderRow,
  ContentArea,
  ScrollableRows,
  ItemRow,
  ItemColumn,
  ServiceColumn,
  HandledColumn,
  ButtonContainer,
  EditButton,
  InventoryContent,
  RoomTitle,
  RoomTitleRow,
  CheckboxContainer,
  CheckboxLabel,
  StyledCheckbox,
  SubCheckbox,
  EstimatedCostInput,
  EstimatedCostInputWrapper,
  DollarSign,
  TypingContainer,
  TypingCursor,
  AnimatedItemRow,
  AnimatedCheckbox,
  RoomTitleWithCursor,
  RoomTitleText,

  AccordionSection,
  AccordionItem,
  AccordionButton,
  AccordionContent,
  FileInputButton

} from './inventory/inventory_styles';


const GenerateInventory = () => {
  // State for inventory content and checkboxes
  const [content, setContent] = useState('');
  const location = useLocation();
  const [allCheckboxes, setAllCheckboxes] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const { formData, setFormData } = useContext(FormContext);
  const [isFromReview, setIsFromReview] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  
  /* New checkboxes */ 
  const [furnitureAssembly, setFurnitureAssembly] = useState(false);
  const [goingToNewHome, setGoingToNewHome] = useState(true);
  const [goingToDifferentAddress, setGoingToDifferentAddress] = useState(false);
  const [goingToStorage, setGoingToStorage] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const { moveType } = formData;
  const originAddress = formData.currentAddress;
  const destinations = formData.destinations;
  const abbreviatedDestinations = formData.abbreviatedDestinations;


    // Initialize inventoryItems from formData or location state
  const [inventoryItems, setInventoryItems] = useState(() => {
    return formData.inventoryItems && Object.keys(formData.inventoryItems).length > 0
      ? formData.inventoryItems
      : {};
  });
  const { openAIGenerator } = useOpenAI();

  const roomResults = location.state?.results || {};
  const analysisResult = location.state?.result || '';
  const [cursorPosition, setCursorPosition] = useState({ room: null, itemIndex: null, detailIndex: null });

  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);

  // Typing effect state
  const [typingIndex, setTypingIndex] = useState(0);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [typingText, setTypingText] = useState('');
  const [currentTypingItem, setCurrentTypingItem] = useState(null);


  const [roomOrder, setRoomOrder] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [cursorOnlyRoom, setCursorOnlyRoom] = useState(null);
  const [processedRooms, setProcessedRooms] = useState([]);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [processingQueue, setProcessingQueue] = useState([]);

  /****** Responsible for the kind of inventory experience we receive upon navigation */
  useEffect(() => {
    if (location.state?.fromReview) {
      setIsFromReview(true);
      setInventoryItems(formData.inventoryItems);
      setRoomOrder(Object.keys(formData.inventoryItems));
      setIsProcessingComplete(true);
      setIsNextDisabled(false); // Enable the Next button
    } else if (formData.inventoryItems && Object.keys(formData.inventoryItems).length > 0) {
      setInventoryItems(formData.inventoryItems);
      setRoomOrder(Object.keys(formData.inventoryItems));
      setIsProcessingComplete(true);
      setIsNextDisabled(false); // Enable the Next button
    } else if (location.state?.photosByRoom) {
      const roomNames = Object.keys(location.state.photosByRoom);
      setRoomOrder(roomNames);
      setIsGenerating(true);
      setIsNextDisabled(true); // Disable the Next button until processing is complete
      processInventory();
    }
  }, [location.state, formData.inventoryItems]);

  /* ***** Checkbox Changes *****
  * shouldShowCheckbox - Determines whether a checkbox should be shown based on the move type
  * handleAllCheckboxChange - Handles changes to the "all" checkboxes for a given room and service
  * handlePackedChange - Handles changes to the "isPacked" checkbox for a specific item
  * handleUnpackChange - Handles changes to the "isUnpacked" checkbox for a specific item
  * handleHighValueChange - Handles changes to the "isHighValue" checkbox for a specific item
  * 
  */

  const shouldCheckCheckbox = (checkboxType) => {
    switch (moveType) {
      case 'Packing, moving, and unpacking':
        return true; // Show all checkboxes
      case 'Packing and moving':
        return checkboxType !== 'isUnpacked';
      case 'Moving and unpacking':
        return checkboxType !== 'isPacked';
      case 'Packing and unpacking':
        return checkboxType !== 'isMoved';
      case 'Just moving':
        return checkboxType === 'isMoved';
      case 'Just packing':
        return checkboxType === 'isPacked';
      case 'Just unpacking':
        return checkboxType === 'isUnpacked';
      default:
        return true; // Default behavior is to show all checkboxes
    }
  };
  const handleServiceChange = (room, itemIndex, service, checked) => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) => 
        index === itemIndex ? { ...item, [service]: checked } : item
      )
    }));
  
    // Check if all items in the room have this service checked
    const allChecked = inventoryItems[room].every(item => item[service]);
    setAllCheckboxes(prev => ({
      ...prev,
      [room]: { ...prev[room], [service]: allChecked }
    }));
    // Handle furniture assembly/disassembly separately
    if (service === 'furnitureAssembly') {
      setFurnitureAssembly(checked);
    }
  };

  const handleAllCheckboxChange = (room, service, checked) => {
    setAllCheckboxes(prev => ({
      ...prev,
      [room]: { ...prev[room], [service]: checked }
    }));
  
    setInventoryItems(prevItems => ({
      ...prevItems,
      [room]: prevItems[room].map(item => ({
        ...item,
        [service]: checked,
        ...(service === 'isPacked' && { needsPacking: !checked })
      }))
    }));
  };

  
    const handleHighValueChange = (room, itemIndex, isHighValue) => {
      setInventoryItems(prevItems => ({
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? { ...item, isHighValue, estimatedCost: isHighValue ? '' : null } : item
        )
      }));
    };
  
    const handleEstimatedCostChange = (room, itemIndex, cost) => {
      setInventoryItems(prevItems => ({
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? { ...item, estimatedCost: cost } : item
        )
      }));
    };

    /* ****** Handle Services Checkboxes ********/
    const handleGoingToDifferentAddress = (room, itemIndex, checked) => {
      if (checked) {
        setShowPopup(true);
        setCurrentItemIndex(itemIndex);
        setCurrentRoom(room);
      } else {
        handleDestinationChange(room, itemIndex, originAddress);
      }
      
      setInventoryItems(prevItems => ({
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? {
            ...item,
            goingToDifferentAddress: checked,
            goingToStorage: false,
            destination: checked ? null : originAddress
          } : item
        )
      }));
    };
  
  
    const handleDestinationChange = (room, itemIndex, newDestination) => {
      setInventoryItems(prevItems => ({
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? {
            ...item,
            destination: newDestination || originAddress,
            goingToStorage: false,
            goingToDifferentAddress: newDestination !== originAddress && newDestination !== null
          } : item
        )
      }));
    };
  
  
    const handleAddDestination = (newAddress) => {
      const abbreviated = newAddress.split(',').slice(0, 2).join(',');
      setFormData(prevData => ({
        ...prevData,
        destinations: [...prevData.destinations, newAddress],
        abbreviatedDestinations: [...prevData.abbreviatedDestinations, abbreviated]
      }));
    };
  
    
    const handleGoingToStorage = (room, itemIndex, checked) => {
      setInventoryItems(prevItems => ({
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? {
            ...item,
            goingToStorage: checked,
            destination: checked ? null : abbreviatedDestinations[0],
            goingToDifferentAddress: false
          } : item
        )
      }));
    };

  /* *************************************** */
  

  /* ***** Inventory Extraction and Text Generation *****
  * extractInventoryItems - Extracts inventory items from the plain text content; may need to adjust parameter
  * First UseEffect - 
  * Second USeEffect - Typing Effect Logic
  * 
  * 
  */
  const extractInventoryItems = (analysis) => {
    const lines = analysis.split('\n');
    const items = [];
    let currentItem = null;

    lines.forEach(line => {
      line = line.trim();
      if (line.startsWith('-') && !line.startsWith('--')) {
        if (currentItem) items.push(currentItem);
        currentItem = { name: line.slice(1).trim(), details: [],           
          isMoved: true,
          isPacked: true,
          isUnpacked: true,
          isHighValue: false,
          estimatedCost: null};
      } else if (line.startsWith('--') && currentItem) {
        currentItem.details.push(line.slice(2).trim());
      }
    });
    if (currentItem) items.push(currentItem);

    return items;
  };


  /* ******* Core Functionality ********* 
  * 
  *
  * 
  *  
  * 
  * 
  * 
  */
  
  /* ******* Effect for processing inventory data ********* */
  const processInventory = async () => {
    if (!location.state || !location.state.photosByRoom) {
      console.error("No photo data available");
      return;
    }
  
    const { initialResult, photosByRoom } = location.state;
    const roomNames = Object.keys(photosByRoom);
    setRoomOrder(roomNames);
  
    // Start the generation process
    setIsGenerating(true);  // Set generating to true here
  
    // Process the initial result first
    if (initialResult) {
      setProcessingQueue([initialResult]);
    }
  
    // Set cursor for all rooms initially
    setRoomOrder(roomNames);
    setCursorOnlyRoom(roomNames[0]);
  
    if (openAIGenerator) {
      for await (const result of openAIGenerator) {
        if (result.error) {
          console.error('Error:', result.error);
        } else {
          setProcessingQueue(prevQueue => [...prevQueue, result]);
        }
      }
      // setIsNextDisabled(false); // Enable the Next button
    }
  };
  useEffect(() => {
    if (processedRooms.length === roomOrder.length && isGenerating) {
      setIsProcessingComplete(true);
      setIsGenerating(false);
    }
  }, [processedRooms, roomOrder, isGenerating]);

  
  useEffect(() => {
    const processNextRoom = async () => {
      if (processingQueue.length > 0 && !currentRoom) {
        const nextRoom = processingQueue[0];
        setProcessingQueue(prevQueue => prevQueue.slice(1)); // Remove the processed room from the queue
        setCursorOnlyRoom(nextRoom.room); // Display the cursor while waiting for the room to be processed
        await processRoom(nextRoom);
      }
    };
  
    processNextRoom();
  }, [processingQueue, currentRoom]);
  
  const processRoom = async (result) => {
    const { room, analysis } = result;
    const items = extractInventoryItems(analysis);
    setCurrentRoom(room);
  
    for (let item of items) {
      setCurrentTypingItem(item);
      await typeItem(item);

      const processedItem = {
        ...item,
        isMoved: shouldCheckCheckbox('isMoved'),
        isPacked: shouldCheckCheckbox('isPacked'),
        isUnpacked: shouldCheckCheckbox('isUnpacked'),
      };

      setAllCheckboxes(prev => ({
        ...prev,
        [room]: {
          isMoved: shouldCheckCheckbox('isMoved'),
          isPacked: shouldCheckCheckbox('isPacked'),
          isUnpacked: shouldCheckCheckbox('isUnpacked'),
        }
      }));

      setInventoryItems(prev => ({
        ...prev,
        [room]: [...(prev[room] || []), processedItem],
      }));
    }
    setCurrentTypingItem(null);
    setCurrentRoom(null);
    setCursorOnlyRoom(null); // Clear the cursor-only state after processing
    setProcessedRooms(prev => [...prev, room]);
  };

// Improved typing effect with inline cursor alignment
const typeItem = async (item) => {
  const fullText = `${item.name}\n${item.details.map((detail) => `  - ${detail}`).join('\n')}`;
  for (let i = 0; i <= fullText.length; i++) {
    setTypingText(fullText.slice(0, i));
    // Update cursor position
    if (i === 0) {
      setCursorPosition({ room: currentRoom, itemIndex: inventoryItems[currentRoom]?.length || 0, detailIndex: null });
    } else if (i > item.name.length) {
      const detailIndex = fullText.slice(0, i).split('\n').length - 2;
      setCursorPosition({ room: currentRoom, itemIndex: inventoryItems[currentRoom]?.length || 0, detailIndex });
    }
    await new Promise((resolve) => setTimeout(resolve, 30)); // Adjust typing speed as needed
  }
  setTypingText(''); // Clear text once typing is done
  setCursorPosition({ room: null, itemIndex: null, detailIndex: null });
};




  /* *************************************** */


  /* ***** Edit Inventory Logic *****
  * handleEditToggle - Toggles between "edit" and "done" modes
  * handleItemChange - Handles changes to an item's name
  * handleDetailChange - Handles changes to an item's detail
  * handleAddNewItem - Adds a new item to the inventory
  * handleDeleteItem - Deletes an item from the inventory
  * saveToHistory - Saves the current inventory state to history
  * handleUndo - Undoes the last inventory change
  * handleRedo - Redoes the last inventory change
  * Second UseEffect - Saves the current state to history when editing
  * 
  */
  const handleEditToggle = () => {
    if (isEditing) {
      // On toggling from "edit" to "done," check if any items have an empty name and remove them
      const cleanedInventoryItems = {};
      Object.entries(inventoryItems).forEach(([room, items]) => {
        cleanedInventoryItems[room] = items.filter((item) => item.name.trim() !== '');
      });
      setInventoryItems(cleanedInventoryItems);
    }
    setIsEditing(!isEditing);
  };


  const handleItemChange = (room, itemIndex, newValue) => {
    setInventoryItems((prevItems) => {
      const updatedItems = prevItems[room].map((item, index) =>
        index === itemIndex ? { ...item, name: newValue } : item
      );
      // Automatically remove the item if its name is empty
      if (newValue.trim() === '') {
        return {
          ...prevItems,
          [room]: updatedItems.filter((item, index) => index !== itemIndex)
        };
      }
      return {
        ...prevItems,
        [room]: updatedItems
      };
    });
  };
  const handleDetailChange = (room, itemIndex, detailIndex, newValue) => {
    setInventoryItems((prevItems) => ({
      ...prevItems,
      [room]: prevItems[room].map((item, index) =>
        index === itemIndex
          ? {
              ...item,
              details: item.details.map((detail, idx) =>
                idx === detailIndex ? newValue : detail
              ),
            }
          : item
      ),
    }));
  };
  const handleAddNewItem = (room) => {
    console.log(`Adding new item to room: ${room}`);
  
    setIsEditing(true);
    setInventoryItems((prevItems) => {
      const updatedRoomItems = [
        ...(prevItems[room] || []),
        {
          name: ' ',
          details: [' '],
          isMoved: true,
          isPacked: true,
          isUnpacked: true,
          isHighValue: false,
          estimatedCost: '',
        },
      ];
  
      console.log(`Updated items for ${room}:`, updatedRoomItems);
  
      // Use setTimeout to ensure all state updates and re-renders have completed
      setTimeout(() => {
        const contentArea = document.querySelector('.content-area');
        console.log('Content area:', contentArea);
  
        if (contentArea) {
          const roomElements = contentArea.querySelectorAll(`[data-room="${room}"]`);
          console.log(`Room elements found for ${room}:`, roomElements);
  
          if (roomElements.length > 0) {
            const lastRoomElement = roomElements[roomElements.length - 1];
            const itemElements = lastRoomElement.querySelectorAll('.item-details');
            console.log(`Number of item elements found in last room element:`, itemElements.length);
  
            if (itemElements.length > 0) {
              const lastItemElement = itemElements[itemElements.length - 1];
              console.log(`Scrolling to:`, lastItemElement);
  
              lastItemElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
              console.log('Scroll executed');
            } else {
              console.error(`No item elements found in room ${room}`);
            }
          } else {
            console.error(`No room elements found for ${room}`);
          }
        } else {
          console.error('Content area not found');
        }
      }, 100); // Small delay to ensure DOM updates
  
      return {
        ...prevItems,
        [room]: updatedRoomItems,
      };
    });
  };

  const handleDeleteItem = (room, itemIndex) => {
    const updatedInventoryItems = {
      ...inventoryItems,
      [room]: inventoryItems[room].filter((item, index) => index !== itemIndex),
    };
    
    // Save the new state to history.
    saveToHistory(updatedInventoryItems);
    
    // Set the new state.
    setInventoryItems(updatedInventoryItems);
  };
  const saveToHistory = (newInventoryItems) => {
    // Prevent saving to history if the new state is identical to the current one.
    if (currentHistoryIndex >= 0 && inventoryHistory[currentHistoryIndex] === newInventoryItems) {
      return; // Don't save if the state hasn't changed.
    }
    
    const newHistory = inventoryHistory.slice(0, currentHistoryIndex + 1); // Remove "future" history when a new change is made.
    newHistory.push(newInventoryItems);
    setInventoryHistory(newHistory);
    setCurrentHistoryIndex(newHistory.length - 1);
  };
  const handleUndo = () => {
    if (currentHistoryIndex >= 0) {
      setCurrentHistoryIndex((prevIndex) => prevIndex - 1);
      setInventoryItems(inventoryHistory[currentHistoryIndex - 1]);
    }
  };
  
  const handleRedo = () => {
    if (currentHistoryIndex <= inventoryHistory.length - 1) {
      setCurrentHistoryIndex((prevIndex) => prevIndex + 1);
      setInventoryItems(inventoryHistory[currentHistoryIndex + 1]);
    }
  };
  useEffect(() => {
    if (isEditing) {
      saveToHistory(inventoryItems); // Automatically saves the current state when editing
    }
  }, [inventoryItems, isEditing]);

  
    /****** Service & High Value Checkbox Logic ****
   * 
   * 
   * 
   * 
   * 
   * 
  */
  const handleMoveChange = (room, itemIndex, isMoved) => {
    setInventoryItems(prevItems => {
      const newItems = {
        ...prevItems,
        [room]: prevItems[room].map((item, index) => 
          index === itemIndex ? { ...item, isMoved } : item
        )
      };
      
      const allMoved = newItems[room].every(item => item.isMoved);
      setAllCheckboxes(prev => ({
        ...prev,
        [room]: { ...prev[room], isMoved: allMoved }
      }));

      return newItems;
    });
  };


  /* *************************************** */

  /* ***** Navigation Logic ***** */

  const navigate = useNavigate();

  /* Keep the state if we navigate back to the previous page */
  const handleBack = () => {
    if (isFromReview) {
      navigate('/review', { state: { ...location.state, inventoryItems } });
    } else {
      navigate('/virtual-walkthrough', { state: location.state });
    }
  };
  const handleNext = () => {
    if (isProcessingComplete) {
      setFormData(prevData => ({
        ...prevData,
        inventoryItems: inventoryItems
      }));
      navigate('/destination', { state: { ...location.state, inventoryItems } });
    }
  };
  useEffect(() => {
    setIsNextDisabled(isGenerating || !isProcessingComplete );
  }, [isGenerating, isProcessingComplete, isFromReview]);


/* *************************************** */

const renderInventory = () => {
  return (
    <ScrollableRows >
      {roomOrder.map((room) => (
        <React.Fragment key={room}>
          <RoomTitleRow>
            <ItemColumn>
              <RoomTitleWithCursor>
                <RoomTitleText>{room}</RoomTitleText>
                {(cursorOnlyRoom === room || (!inventoryItems[room] && !processedRooms.includes(room))) && <TypingCursor />}
              </RoomTitleWithCursor>
              <button
                className="add-item-button"
                style={{ marginTop: '20px', marginBottom: '10px' }} // Adjust the margin as needed
                onClick={() => handleAddNewItem(room)}
              >
                + Add Item
              </button>
            </ItemColumn>
            <ServiceColumn>
            <CheckboxContainer>
              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isMoved}
                  onChange={(e) => handleAllCheckboxChange(room, 'isMoved', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isMoved')}
                />
                Move all {room} items
              </CheckboxLabel>

              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isPacked}
                  onChange={(e) => handleAllCheckboxChange(room, 'isPacked', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isPacked')}
                />
                Pack all {room} items
              </CheckboxLabel>

              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={allCheckboxes[room]?.isUnpacked}
                  onChange={(e) => handleAllCheckboxChange(room, 'isUnpacked', e.target.checked)}
                  defaultChecked={shouldCheckCheckbox('isUnpacked')}
                />
                Unpack all {room} items
              </CheckboxLabel>
            </CheckboxContainer>
            </ServiceColumn>
            <HandledColumn>
              {/* Empty for room title row */}
            </HandledColumn>
          </RoomTitleRow>
          {inventoryItems[room]?.map((item, itemIndex) => (
            <AnimatedItemRow key={`${room}-${itemIndex}`} data-room={room}>
              <ItemColumn>
                <InventoryContent>
                  <div className="item-details">
                    {isEditing ? (
                      // Editing Mode
                      <input
                        type="text"
                        value={item.name}
                        onChange={(e) => handleItemChange(room, itemIndex, e.target.value)}
                        className="edit-input"
                      />
                    ) : (
                      // Typing Effect
                      <div>
                        {item.name}
                        {cursorPosition.room === room &&
                        cursorPosition.itemIndex === itemIndex &&
                        cursorPosition.detailIndex === null && <TypingCursor />}
                      </div>
                    )}
                    {item.details.map((detail, detailIndex) => (
                      <div key={detailIndex} style={{ paddingLeft: '20px' }}>
                        {isEditing ? (
                          <input
                            type="text"
                            value={detail}
                            onChange={(e) => handleDetailChange(room, itemIndex, detailIndex, e.target.value)}
                            className="edit-input"
                          />
                        ) : (
                          <div>
                            - {detail}
                            {cursorPosition.room === room &&
                              cursorPosition.itemIndex === itemIndex &&
                              cursorPosition.detailIndex === detailIndex && <TypingCursor />}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <ItemColumn className="delete-icon-column">
                    {isEditing && (
                      <img
                        src={Trash}
                        alt="Delete"
                        className="delete-icon"
                        onClick={() => handleDeleteItem(room, itemIndex)}
                      />
                    )}
                  </ItemColumn>
                </InventoryContent>
              </ItemColumn>
              <ServiceColumn>
                <CheckboxContainer>

                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isMoved} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isMoved', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isMoved')}
                    />
                      Moved by movers
                    </CheckboxLabel>


                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isPacked} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isPacked', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isPacked')}
                    />
                      Packed by movers
                    </CheckboxLabel>


                    <CheckboxLabel>
                    <AnimatedCheckbox
                      type="checkbox"
                      checked={item.isUnpacked} // Use the item's state directly
                      onChange={(e) => handleServiceChange(room, itemIndex, 'isUnpacked', e.target.checked)}
                      defaultChecked={shouldCheckCheckbox('isUnpacked')}
                    />
                      Unpacked by movers
                    </CheckboxLabel>
                    <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={item.furnitureAssembly}
                        onChange={(e) => handleServiceChange(room, itemIndex, 'furnitureAssembly', e.target.checked)}
                      />
                      Furniture assembly/disassembly?
                    </CheckboxLabel>
                </CheckboxContainer>
              </ServiceColumn>
              <HandledColumn>
                <CheckboxContainer>
                <CheckboxLabel>
                      <AnimatedCheckbox
                        type="checkbox"
                        checked={!item.goingToDifferentAddress && !item.goingToStorage}
                        onChange={(e) => handleDestinationChange(room, itemIndex, e.target.checked ? abbreviatedDestinations[0] : null)}
                      />
                      {`Moving to ${abbreviatedDestinations[0]}`}
                    </CheckboxLabel>
                    <SubCheckbox>
                      <CheckboxLabel>
                        <AnimatedCheckbox
                          type="checkbox"
                          checked={item.goingToDifferentAddress}
                          onChange={(e) => handleGoingToDifferentAddress(room, itemIndex, e.target.checked)}
                        />
                        {item.destination && item.destination !== abbreviatedDestinations[0]
                          ? `Moving to ${item.destination}`
                          : "Moving to a different address?"}
                      </CheckboxLabel>
                    </SubCheckbox>

              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={item.goingToStorage}
                  onChange={(e) => handleGoingToStorage(room, itemIndex, e.target.checked)}
                />
                Going to the moving company's storage
              </CheckboxLabel>
              {/* Existing High Value checkbox */}
              <CheckboxLabel>
                <AnimatedCheckbox
                  type="checkbox"
                  checked={item.isHighValue}
                  onChange={(e) => handleHighValueChange(room, itemIndex, e.target.checked)}
                />
                High Value?
              </CheckboxLabel>
              {item.isHighValue && (
                <EstimatedCostInputWrapper>
                  <DollarSign>$</DollarSign>
                  <EstimatedCostInput
                    type="text"
                    placeholder="Estimated cost"
                    value={item.estimatedCost || ''}
                    onChange={(e) => handleEstimatedCostChange(room, itemIndex, e.target.value)}
                  />
                </EstimatedCostInputWrapper>
              )}
              </CheckboxContainer>
              </HandledColumn>
            </AnimatedItemRow>
          ))}
          {/* {isEditing && (
            <ItemColumn>
              <button className="add-item-button" onClick={() => handleAddNewItem(room)}>
                + Add Item
              </button>
            </ItemColumn>
          )} */}
          {currentRoom === room && currentTypingItem && !isEditing && (
            <AnimatedItemRow>
              <ItemColumn>
                <InventoryContent>
                  <TypingContainer>
                    <div className="item-details">
                      {typingText.split('\n').map((line, index) => (
                        <div key={index} style={index > 0 ? { paddingLeft: '20px' } : {}}>
                          {line}
                        </div>
                      ))}
                      <TypingCursor style={{ display: 'inline-block', marginLeft: '2px' }} />
                    </div>
                  </TypingContainer>
                </InventoryContent>
              </ItemColumn>
              <ServiceColumn />
              <HandledColumn />
            </AnimatedItemRow>
          )}
        </React.Fragment>
      ))}
    </ScrollableRows>
  );
};

return (
  <Layout>
    <InventoryTitle>
      My Inventory <img src={InventoryIcon} alt="inventory-icon" className="inventory-icon" />
    </InventoryTitle>
    <StyledInventoryBox>
      <HeaderRow>
        <div>
          Items
          <img src={Item} alt="logo" className="item-icon" />
        </div>
        <div>
          Services
          <img src={Trolly} alt="logo" className="trolly-icon" />
        </div>
        <div>
          Handling
          <img src={Value} alt="logo" className = "value-icon"/>
        </div>
      </HeaderRow>
      <ButtonContainer>
          <EditButton onClick={handleEditToggle}>
            <div className="edit-icon">{isEditing ? '✓' : '✎'}</div>
            <div className="edit-text">{isEditing ? 'Done' : 'Edit My Inventory'}</div>
          </EditButton>
          {isEditing ? (
            <div className="undo-redo-container">
              <button
                className="undo-button"
                onClick={handleUndo}
                disabled={currentHistoryIndex <= 0}
              >
                Undo
              </button>
              <button
                className="redo-button"
                onClick={handleRedo}
                disabled={currentHistoryIndex >= inventoryHistory.length - 1}
              >
                Redo
              </button>
            </div>
          ) : (
            <CopyComponent inventoryItems={inventoryItems} />
          )}
        </ButtonContainer>
      <ContentArea className="content-area">
        {renderInventory()}
      </ContentArea>
      {showPopup && (
        <DestinationSelectionPopup
          destinations={destinations}
          abbreviatedDestinations={abbreviatedDestinations}
          onSelect={(address) => {
            handleDestinationChange(currentRoom, currentItemIndex, address);
            setShowPopup(false);
          }}
          onClose={() => setShowPopup(false)}
          onAddDestination={handleAddDestination}
        />
      )}
    </StyledInventoryBox>
    <div className="generate-inventory-nav">
      <NavigationArrows
        onPrevClick={handleBack}
        onNextClick={handleNext}
        prevLabel={isFromReview ? 'Back to Review' : 'Start Over'}
        nextLabel="Next"
        isNextDisabled={isNextDisabled}
      />
    </div>
  </Layout>
);
};

export default GenerateInventory;