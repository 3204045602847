import React from 'react';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import '../../styles/layout/nav_arrows.css';

const NavigationArrows = ({ onPrevClick, onNextClick, prevLabel = 'Previous', nextLabel = 'Next', inverted, isNextDisabled }) => {
  return (
    <div className={`navigation-container ${inverted ? 'inverted' : ''}`}>
      <button 
        onClick={onPrevClick} 
        className={`navigation-button navigation-button-prev ${inverted ? 'inverted-button' : ''}`}
      >
        <FaArrowCircleLeft />
        <span className="navigation-button-text">{prevLabel}</span>
      </button>
      <button 
        onClick={onNextClick} 
        className={`navigation-button navigation-button-next ${isNextDisabled ? 'disabled' : ''} ${inverted ? 'inverted-button' : ''}`}
        disabled={isNextDisabled}
      >
        <span className="navigation-button-text">{nextLabel}</span>
        <FaArrowCircleRight />
      </button>
    </div>
  );
};

export default NavigationArrows;