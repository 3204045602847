import React , {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {OpenAIProvider}  from './components/openai/openai_context';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
import { FormProvider } from './components/homeOwner/context/form_context';
import { AddressProvider } from './components/homeOwner/context/address_context';
import './protect.css'

/* Homeowner Components */
import SignupLogin from './components/homeOwner/register/homeowner-sign_log';
import ResetPassword from './components/homeOwner/register/forgot';
import LandingPage from './components/landing_page/landing_page';
import BeginTour from './components/homeOwner/begin_tour';
import VirtualWalkthrough from './components/homeOwner/virtual_walkthrough';
import GenerateInventory from './components/homeOwner/generate_inventory';
import Destination from './components/homeOwner/desination';
import FinalMessage from './components/homeOwner/final_message';
import FinalReview from './components/homeOwner/final_review';
import CompanyProfile from './components/homeOwner/company_profile';
import Terms from './components/layout/terms';


/* Moving Company Components */
import Listings from './components/movingCompany/postings'
import BidForm from './components/movingCompany/bid_form'
import ViewListing from './components/movingCompany/view_listing'
import LexPro from './components/movingCompany/lexpro'

import Dashboard from './components/homeOwner/dashboard';
import { AuthProvider } from './firebase/auth_context';
import PropertyInformation from './components/homeOwner/property_info';

/* Testing */
import OpenAIComponent from './components/openai/sample_return';
import ComingSoon from './components/movingCompany/coming_soon';
import PasscodePrompt from './pass_protect';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const inDev = process.env.NODE_ENV === 'development';
  const [showPassword, setShowPassword] = useState(false);
  const correctPasscode = 'YCombinator-F24'; 

  const handlePasscodeSubmit = (e) => {
    e.preventDefault();
    const enteredPasscode = e.target.elements.passcode.value;
    if (enteredPasscode === correctPasscode) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect passcode. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (!isAuthenticated && !inDev) {
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <div className = "note-container">
              <div className="note">
                Note: Lexos is not optimized for mobile devices as of this release 
              </div>
            </div>
          <h2 className="label-enter">Enter Passcode</h2>
          <form onSubmit={handlePasscodeSubmit}>
            <div className="input-wrapper">
              <input 
                type={showPassword ? "text" : "password"} 
                name="passcode" 
                placeholder="Enter passcode" 
                className="input-field"
              />
              <button 
                type="button" 
                className="toggle-button" 
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <button type="submit" className="submit-pwd-button">Submit</button>
          </form>
        </div>
      </div>
    );
  }

  if (!isAuthenticated && !inDev) {
    return (
      <div>
        <h2>Enter Passcode</h2>
        <form onSubmit={handlePasscodeSubmit}>
          <input type="password" name="passcode" placeholder="Enter passcode" />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return (
    <OpenAIProvider>
    <AddressProvider>

    <FormProvider>
    <AuthProvider>
      <Router>
        <Routes>
          {/* HomeOwner paths */}
          <Route path="/" element={ <LandingPage /> } />
          <Route path="/login" element={<SignupLogin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/property-info" element={<PropertyInformation />} />
          <Route path="/begin-tour" element={<BeginTour/>} />
          <Route path="/virtual-walkthrough" element={<VirtualWalkthrough />} />
          <Route path="/generate-inventory" element={<GenerateInventory />} />
          <Route path="/destination" element={<Destination />} />
          <Route path="/review" element={<FinalReview />} />
          <Route path="/final-message" element={<FinalMessage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/company-profile" element={<CompanyProfile />} />
           <Route path="/terms" element={<Terms />} />
          {/* Moving Company paths */}
          <Route path="/become-lexpro" element={<LexPro/>} />
          <Route path="/listings" element={<Listings />} />
          <Route path="/bid-form" element= {<BidForm />} />
          <Route path="/view-listing" element={<ViewListing/>} />
          {/* Reference later */}
          
          {/* Testing */}
          <Route path="/openai" element={<OpenAIComponent />} />
          <Route path="/move-checklist" element={<ComingSoon />} />
          <Route path="/my-moves" element={<ComingSoon />} />
          <Route path="/edit-move-info" element={<ComingSoon />} />
          <Route path="/account" element={<ComingSoon />} />
        </Routes>
      </Router>
    </AuthProvider>
    </FormProvider>
    </AddressProvider>
    </OpenAIProvider>
  );
}

export default App;
