import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout';
import '../../styles/layout/terms.css';

const Terms = () => {
  const navigate = useNavigate();
  
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    (function(d, s, id) {
      var js, tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://app.termly.io/embed-policy.min.js';
      tjs.parentNode.insertBefore(js, tjs);
    })(document, 'script', 'termly-jssdk');
  }, []);

  return (
    <Layout>
      <div className="terms-container">
      <button onClick={handleBack} className="terms-back-button">
              Back
            </button>
        <div className="terms-content">
          <div
            name="termly-embed"
            data-id="c8e9e06c-a3d9-4aff-9c53-15c4711dfefd"
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;