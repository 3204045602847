import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../layout';
import '../../styles/homeOwner/begin_tour.css';
import Photography from '../../assets/lexos_imgs/person-photo-taking.png';
import NavigationArrows from '../layout/nav_arrows';

const BeginTour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    // Forward the state received from the previous page when navigating to Virtual Walkthrough
    console.log("Received begin tour:", location.state);
    navigate('/virtual-walkthrough', { state: location.state });
  };

  const handleBack = () => {
    navigate('/property-info', { state: location.state });
  };

  return (
    <Layout>
      <div className="begin-tour-container">
        <h1>AI-Powered Virtual Walkthrough</h1>
        <div className="content-wrapper">
          <p className="intro">
            We'll guide you through capturing videos of each room based on your property information
          </p>
          <img src={Photography} alt="Augmented Reality" className="room-image" style={{ maxHeight: '300px' }} />
          <div className="instructions-box">
            <h2>Instructions for Optimal Results</h2>
            <ul>
              <li>Take clear photos to allow the AI to detect each object</li>
              <li>Open all cabinets and drawers to capture extra inventory</li>
              <li>Afterward, you can select from your inventory what you'd like the moving company to pack for you</li>
            </ul>
          </div>
          <div className="note-box">
            <h2>Additional Notes</h2>
            <p>If you forgot to capture a photo, you can:</p>
            <ul>
              <li>Take a photo of the missing item, and it will be added into the inventory</li>
              <li>Add the object manually if preferred</li>
            </ul>
          </div>
          <div className="privacy-note">
            Your inventory will not be saved by Lexos after the move is complete
          </div>
          <div className="begin-tour-nav">
          <NavigationArrows
              onPrevClick={handleBack}
              onNextClick={handleNext}
              prevLabel={'Previous'}
              nextLabel={"Begin Walkthrough"}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BeginTour;