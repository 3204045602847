import Layout from '../../components/layout.js';
import '../../styles/movingCos/lexpro.css';
import NavigationArrows from '../layout/nav_arrows';
import { useNavigate } from 'react-router-dom';

export default function LexPro() {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // This takes the user to the previous page
    };

    return (
        <Layout>
            <div className="lexpro-container">
                <h1 className="coming-soon">Coming Soon!</h1>
                <div className="nav-arrows-lp">
                    <button className="prev" onClick={handleBackClick}>Back</button>
                </div>
            </div>
        </Layout>
    );
}
