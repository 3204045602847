import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { IoIosAddCircleOutline } from "react-icons/io";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: var(--dark-gray-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative; /* Important for positioning buttons */
`;

const DestinationOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const AddressInput = styled.div`
  margin-top: 20px;
  .search-bar {
    display: relative;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
  }

  .search-input {
    width: 100%;
    border-radius: 50px;
    padding: 15px 20px;
    padding-right: 60px;
    font-size: 16px;
    border: 1px solid #d0d0d0;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.06), 0 6px 12px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.08);
    transition: all 0.3s ease;
    background-color: #fff;
    color: var(--dark-gray-color);
  }

  .search-input:focus {
    box-shadow: 0 8px 16px rgba(0,0,0,0.15), 0 4px 8px rgba(0,0,0,0.1);
    outline: none;
    border-color: #b0b0b0;
  }

  .search-input::-webkit-search-cancel-button {
    display: none;
  }

  .btn-remove {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn-remove img {
    width: 20px;
    height: 20px;
  }
`;
const AddIcon = styled(IoIosAddCircleOutline)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(34%) sepia(77%) saturate(1422%) hue-rotate(233deg) brightness(94%) contrast(93%);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    filter: brightness(0.9);
  }
`;

const CancelButton = styled(Button)`
  background-color: red;
  color: white;
  
  &:hover {
    background-color: #ff1a1a;
  }
`;

const DoneButton = styled(Button)`
  background-color: green;
  color: white;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;

  &:checked {
    background-color: var(--primary-color);
    &:after {
      content: '✓';
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

const DestinationSelectionPopup = ({ destinations, abbreviatedDestinations, onSelect, onClose, onAddDestination }) => {
  const [newAddress, setNewAddress] = useState('');
  const [selectedDestination, setSelectedDestination] = useState(null);

  useEffect(() => {
    if (abbreviatedDestinations.length > 0) {
      setSelectedDestination(abbreviatedDestinations[0]);
    }
  }, [abbreviatedDestinations]);

  const handleSelect = (address) => {
    setSelectedDestination(address);
  };

  const handleAddDestination = () => {
    if (newAddress) {
      onAddDestination(newAddress);
      setNewAddress('');
    }
  };

  const handleDone = () => {
    if (selectedDestination) {
      onSelect(selectedDestination);
    }
    onClose();
  };

  
 return (
    <PopupOverlay>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <h2>Select Destination</h2>
        {abbreviatedDestinations.map((dest, index) => (
          <DestinationOption key={index}>
            <StyledCheckbox
              type="checkbox"
              id={`dest-${index}`}
              checked={selectedDestination === dest}
              onChange={() => handleSelect(dest)}
            />
            <label htmlFor={`dest-${index}`}>{dest}</label>
          </DestinationOption>
        ))}
        <AddressInput>
        <div className="search-bar">
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => setNewAddress(place.formatted_address)}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'us' }
              }}
              className="search-input"
              placeholder="Add a new destination..."
            />
            <AddIcon onClick={handleAddDestination} />
          </div>
        </AddressInput>
        <ButtonContainer>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DoneButton onClick={handleDone}>Done</DoneButton>
        </ButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default DestinationSelectionPopup;