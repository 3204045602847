import React from 'react';
import Layout from '../../components/layout.js';
import Map from '../../components/movingCompany/map.js';
import ListingCard from '../../components/movingCompany/listing_card.js';
import '../../styles/movingCos/postings.css';

import houseImage from '../../assets/lexos_imgs/interior-house.jpg';
import secondHouse from '../../assets/lexos_imgs/horiz.jpg';

const postingsData = [
  {
    from: '123 Main St, Cleveland, OH',
    to: '456 Elm St, Boston, MA',
    info: '3 beds | 2 baths',
    estimate: '6-8 hour move',
    image: houseImage, 
  },
  {
    from: '1234 Columbus Ave, Boston, MA',
    to: '5678 Columbus Ave, Boston, MA',
    info: '1 bed | 1 bath',
    estimate: '3-5 hour move',
    image: secondHouse,
  },
  // Add more postings here
];

const Postings = () => {
  return (
    <Layout>
    <div className="postings-page">
      <div className="map-container">
        <Map />
      </div>
      <div className="listings-container">
        {postingsData.map((posting, index) => (
          <ListingCard key={index} {...posting} />
        ))}
      </div>
    </div>
    </Layout>
  );
};

export default Postings;