import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { auth } from '../../../firebase/fireconfig';
import { AddressContext } from '../context/address_context';
import '../../../styles/bootstrap_imported.css';
import '../../../styles/homeOwner/register/signup_login.css';
import { faXTwitter, faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../layout';

function SignupLogin() {
  const { address } = useContext(AddressContext);
  const [activeTab, setActiveTab] = useState('login');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [signupPasswordVisible, setSignupPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log('Persistence set to local');
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
      });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, redirect them to the dashboard or property info page
        navigate('/review');
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    // Attach the event listener to the input fields
    const emailInput = document.querySelector('input[type="text"]');
    const passwordInput = document.querySelector('input[type="password"]');

    const clearError = () => setErrorMessage('');

    if (emailInput) emailInput.addEventListener('input', clearError);
    if (passwordInput) passwordInput.addEventListener('input', clearError);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      if (emailInput) emailInput.removeEventListener('input', clearError);
      if (passwordInput) passwordInput.removeEventListener('input', clearError);
    };
  }, []);

  const handleTermsClick = (e) => {
    e.preventDefault();
    navigate('/terms'); // Navigate to the terms and conditions page
  };

  const validateForm = () => {
    if (!email.match(/^\S+@\S+\.\S+$/)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    if (password.length < 8) {
      setErrorMessage('Password should be at least 8 characters.');
      return false;
    }
    if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[^A-Za-z0-9]/.test(password)
    ) {
      setErrorMessage(
        'Password should contain uppercase, lowercase, number, and special character.'
      );
      return false;
    }
    if (activeTab === 'register') {
      if (!fullName.trim()) {
        setErrorMessage('Please enter your full name.');
        return false;
      }
      if (phone && !phone.match(/^\d{10,15}$/)) {
        setErrorMessage('Please enter a valid phone number.');
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
    try {
      if (activeTab === 'register') {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User registered:', user);
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User logged in:', user);
      }
      navigate('/review');
    } catch (error) {
      console.error('Error with authentication:', error.message);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialSignIn = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'google':
          authProvider = new GoogleAuthProvider();
          break;
        case 'facebook':
          authProvider = new FacebookAuthProvider();
          break;
        default:
          throw new Error('Unsupported provider');
      }
      const result = await signInWithPopup(auth, authProvider);
      console.log('User signed in:', result.user);
      navigate('/review');
    } catch (error) {
      console.error('Error with social sign-in:', error.message);
      setErrorMessage(error.message);
    }
  };

  return (
    <Layout>
      <h1 className="register-header">Get Started</h1>
      <div className="auth-container" style={{ minHeight: 'calc(100vh)' }}>
        <div className="auth-tabs">
          <button
            className={`auth-tab ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => setActiveTab('login')}
          >
            Login
          </button>
          <button
            className={`auth-tab ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => setActiveTab('register')}
          >
            Register
          </button>
        </div>

        <div className="auth-content">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="social-buttons">
            <button
              className="social-button facebook"
              aria-label="Sign in with Facebook"
              onClick={() => handleSocialSignIn('facebook')}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </button>
            <button
              className="social-button google"
              aria-label="Sign in with Google"
              onClick={() => handleSocialSignIn('google')}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </button>
            <button className="social-button x" aria-label="Sign in with X">
              <FontAwesomeIcon icon={faXTwitter} />
            </button>
          </div>

          {activeTab === 'login' ? (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
              <div className="password-input-wrapper">
                <input
                  type={loginPasswordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                  aria-label={loginPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  {loginPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <div className="form-footer">
                <label>
                  <input type="checkbox" /> Remember me
                </label>
                {/* Redirect to new password reset page */}
                <button
                  className="forgot-password"
                  type="button"
                  onClick={() => navigate('/reset-password')}
                >
                  Forgot password?
                </button>
              </div>
              <button type="submit" className="submit-button-login" disabled={isLoading}>
                {isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoComplete="name"
              />
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
              <input
                type="tel"
                placeholder="Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                autoComplete="tel"
              />
              <div className="password-input-wrapper">
                <input
                  type={signupPasswordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setSignupPasswordVisible(!signupPasswordVisible)}
                  aria-label={signupPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  {signupPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <label className="terms-label">
                <input type="checkbox" required /> I have read and agree to the
                <span className="terms-link" onClick={handleTermsClick}>
                  {' '}
                  Terms and Conditions
                </span>
              </label>
              <button type="submit" className="submit-button-signup" disabled={isLoading}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
          )}

          {activeTab === 'login' ? (
            <p className="register-link">
              Not a member?{' '}
              <a href="#" onClick={() => setActiveTab('register')}>
                Register
              </a>
            </p>
          ) : (
            <p className="register-link">
              Already a member?{' '}
              <a href="#" onClick={() => setActiveTab('login')}>
                Login
              </a>
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default SignupLogin;
