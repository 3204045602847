import React from 'react';
import '../../styles/homeOwner/company_profile.css';
import Layout from '../layout';
import PinkZebra from '../../assets/lexos_imgs/pink-zebra.png';
import NPCImage from '../../assets/lexos_imgs/npc.png';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import NavigationArrows from '../layout/nav_arrows';

const CompanyProfile = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="company-profile-container">
        <div className="company-header">
          <div className="company-info">
            <div className="company-img-placeholder">
              <img src={PinkZebra} alt="Company Logo" className="zeke" />
            </div>
            <div className="company-details">
              <h2 className="company-name">Pink Zebra Moving</h2>
              <div className="company-rating">
                <span className="rating-text">Rating: 4.7</span>
                <span className="stars">
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStar color="var(--primary-color)" />
                    <FaStarHalfAlt color="var(--primary-color)" />
                </span>
                <span className="review-count">(50 reviews)</span>
              </div>
              <button className="share-btn">Share</button>
            </div>
          </div>
          <div className="breadcrumb">Lexos // Dashboard // Pink Zebra Moving</div>
        </div>

        <div className="company-introduction">
          <h3>Introduction</h3>
          <p>
            Here at Pink Zebra we offer best in class service with competitive rates. We have been in business for over 5 years and have completed over 10,000 moves. Our team is professional, courteous, and efficient. We are fully licensed and insured. We offer a variety of services including packing, unpacking, and storage. Contact us today for a free quote!
          </p>
        </div>

        <div className="company-overview">
          <div className="overview-section">
            <h4>Overview</h4>
            <ul>
              <li>Serves Boston, MA</li>
              <li>Background checked</li>
              <li>6 employees</li>
              <li>4 years in business</li>
            </ul>
          </div>

          <div className="overview-section">
            <h4>Scheduling Policy</h4>
            <p>
              You can reserve this LexPro up to 25 days in advance. They can take your booking request within 24 hours of the requested time.
            </p>
          </div>

          <div className="overview-section">
            <h4>Payment Methods</h4>
            <p>
              This LexPro accepts payments via Apple Pay, Cash, Credit card, Square cash app, Venmo, and Zelle.
            </p>
          </div>

          <div className="overview-section">
            <h4>LexPro Gold Status</h4>
            <p>
              LexPro Gold's are among the highest-rated, most popular professionals on Lexos.
            </p>
          </div>
        </div>

        <div className="business-hours">
          <h4>Business Hours</h4>
          <table>
            <tbody>
              <tr>
                <td>Sun</td>
                <td>Closed</td>
              </tr>
              <tr>
                <td>Mon</td>
                <td>9:00 AM - 11:00 PM</td>
              </tr>
              {/* Add more days as needed */}
              <tr>
                <td>Tue</td>
                <td>9:00 AM - 11:00 PM</td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>9:00 AM - 11:00 PM</td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>9:00 AM - 11:00 PM</td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>9:00 AM - 11:00 PM</td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>9:00 AM - 7:00 PM</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="company-reviews">
          <h3>Reviews</h3>
          <section className="reviews-section">
            <div className="row text-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="d-flex justify-content-center mb-4 ">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Wonderful service. Couldn't have asked for a better moving experience. Highly recommend!"
                </p>
                <p className="font-italic font-weight-normal mb-0">- John McLaughlin</p>
              </div>
              <div className="col-md-6 mb-0">
                <div className="d-flex justify-content-center mb-4">
                    <FaRegCircleUser className="testimonial-icon"/>
                </div>
                <p className="lead my-3 text-muted">
                  "Pink Zebra was fantastic. They were on time, professional, and handled my items with care."
                </p>
                <p className="font-italic font-weight-normal mb-0">- Reza Taleghani </p>
              </div>
            </div>
          </section>
          <div className="property-info-nav">
            <NavigationArrows
              onPrevClick={() => navigate('/dashboard')}
              prevLabel={'Previous'}
              inverted
            />
            </div>
        </div>
      </div>

    </Layout>
  );
};

export default CompanyProfile;
