export async function* DetectOpenAI(photosByRoom) {
  const formData = new FormData();
  
  Object.entries(photosByRoom).forEach(([roomName, roomPhotos]) => {
    roomPhotos.forEach((photo, index) => {
      formData.append('photos', photo, `${roomName}_${index}.jpg`);
      formData.append('room_names', roomName);
    });
  });

  try {
    console.log("Sending data to OpenAI API");
    const response = await fetch('https://lexos-backend-547367433733.us-central1.run.app/api/openai/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      for (const line of lines) {
        if (line.trim()) {
          yield JSON.parse(line);
        }
      }
    }
  } catch (error) {
    console.error('Detailed error:', error);
    console.error('Error stack:', error.stack);
    yield { error: error.message };
  }
}
