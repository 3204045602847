import React from 'react';
import { useNavigate } from 'react-router-dom';

const ListingCard = ({ from, to, info, estimate, image }) => {
    const navigate = useNavigate();
    const handleBidButtonClick = () => {
        navigate('/bid-form');
    };
      const handleCardClick = () => {
        navigate('/view-listing'); // Change this path 
      };
  return (
    <div className="listing-card" onClick={handleCardClick}>
      <img src={image} alt="House" className="listing-image" />
      <div className="listing-info">
        <h3>From: {from}</h3>
        <h3>To: {to}</h3>
        <p>{info}</p>
        <p>Move Estimate: {estimate}</p>
        <button className="listed-submit-bid-button" onClick = {navigate('/bid-form')} >Submit a Bid </button>
      </div>
    </div>
  );
};

export default ListingCard;