import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../layout';
import { FormContext } from '../homeOwner/context/form_context';
import { AddressContext } from '../homeOwner/context/address_context';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { createGlobalStyle } from 'styled-components';
import '../../styles/homeOwner/destination.css';
import NewHomeImage from '../../assets/lexos_imgs/destination-insight.png';
import search_icon from '../../assets/lexos_imgs/search-magnifier.png';
import NavigationArrows from '../layout/nav_arrows';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker';
import CustomCalendar from './custom_calendar';

const Destination = () => {
  const GlobalStyle = createGlobalStyle`
    body {
      font-family: 'Open Sans', sans-serif;
    }
  `;

  const location = useLocation();
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext); 
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(formData.selectedDate || '');
  const [address, setAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false); // New state for submitting status

  useEffect(() => {
    // Check if inventoryItems exist in the location state
    if (location.state && location.state.inventoryItems) {
      // Update the context with the inventoryItems
      setFormData(prevData => ({
        ...prevData,
        inventoryItems: location.state.inventoryItems
      }));
    }
  }, [location.state, setFormData]);

  const datepickerRef = useRef(null);

  useEffect(() => {
    console.log('Destination Component Loaded');
    console.log('Initial Form Data:', formData);
  }, []);

  useEffect(() => {
    // Initialize the datepicker
    $(datepickerRef.current).datepicker({
      autoclose: true,
      todayHighlight: true,
      todayBtn: "linked",
      title: "Select a Preferred Date",
      templates: {
        leftArrow: '«',
        rightArrow: '»'
      },
      // Set the initial date from formData if it exists
      setDate: formData.selectedDate ? new Date(formData.selectedDate) : null, 
    }).on('changeDate', function (e) {
      handleDateSelect(e.format('mm-dd-yyyy')); // Update this line to handle the date format properly
    });
  
    // If there is already a selected date, set it on the calendar
    if (formData.selectedDate) {
      $(datepickerRef.current).datepicker('setDate', new Date(formData.selectedDate));
    }
  }, [formData.selectedDate]);  // Re-run effect when selectedDate changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    setSubmitting(true); // Set submitting status to true

    navigate('/login');
};
  // Lol, update this to go back to the inventory page
  const handleBack = () => {
    navigate('/generate-inventory', { state: { ...location.state, formData } });
  };

  const handlePreferredTimeClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    // Update selectedDate only if a time was chosen
    if (formData.preferredTime) {
      setSelectedDate(formData.preferredTime);
    }
  };

  const handleTimeSlotChange = (e) => {
    const selectedTime = e.target.value;
    const formattedDate = selectedDate.toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric'
    });
    setFormData({
      ...formData,
      selectedDate: formattedDate,
      preferredTime: selectedTime
    });
  };

  const handlePlaceSelected = (place) => {
    if (place && place.formatted_address) {
      setAddress(place.formatted_address);
      setIsValidAddress(true);
      setShowError(false);
    } else {
      setIsValidAddress(false);
    }
  };

  const handleSearchClick = () => {
    if (isValidAddress) {
      navigate('/signup-login');
    } else {
      setShowError(true);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setShowPopup(true);
  };

  const generateTimeSlots = (startTime, endTime, interval) => {
    const slots = [];
    const start = new Date(`2024-01-01T${startTime}:00`);
    const end = new Date(`2024-01-01T${endTime}:00`);

    while (start < end) {
      const nextSlot = new Date(start.getTime() + interval * 60000);
      const slotString = `${start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} - ${nextSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
      slots.push(slotString);
      start.setMinutes(start.getMinutes() + interval);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots('09:00', '17:00', 60);

  return (
    <Layout>
      <GlobalStyle />
      <div className="destination-container">
        <form className="destination-form" onSubmit={handleNext}>
          <h1>Almost There!</h1>
          <p className= "tell-us">Tell us how we can get you to your new home</p>
          <img src={NewHomeImage} alt="New Home" className="img-new-home" />

          <label>
            <h2>Preferred Date & Time:</h2>
            <div className="custom-calendar-container">
              <CustomCalendar onDateSelect={handleDateSelect} initialSelectedDate={formData.selectedDate}/>
              {selectedDate && (
                <input
                  type="text"
                  className="calendar-control"
                  name="preferredTime"
                  value={[formData.selectedDate, formData.preferredTime || selectedDate]}
                  readOnly
                  placeholder="Selected preferred date"
                />
              )}
            </div>
          </label>
          <div className="form-section">
            <label>
              <h3>Will you be present during the move?</h3>
              <div >
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="presentDuringMove"
                    value="Yes"
                    checked={formData.presentDuringMove === 'Yes'}
                    onChange={handleChange}
                    
                  />
                  Yes
                </label>
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="presentDuringMove"
                    value="No"
                    checked={formData.presentDuringMove === 'No'}
                    onChange={handleChange}
                    
                  />
                  No
                </label>
              </div>
            </label>

            {formData.presentDuringMove === 'No' && (
              <label >
                <h3>Instructions for Movers to Access Home:</h3>
                <textarea
                  name="accessInstructions"
                  value={formData.accessInstructions || ''}
                  onChange={handleChange}
                  placeholder="Provide specific instructions for the movers"
                />
              </label>
            )}

            <label>
              <h3>Any specific instructions or notes for the movers?</h3>
              <div >
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="specificInstructions"
                    value="Yes"
                    checked={formData.specificInstructions === 'Yes'}
                    onChange={handleChange}
                    
                  />
                  Yes
                </label>
                <label className= "radio-option">
                  <input
                    type="radio"
                    name="specificInstructions"
                    value="No"
                    checked={formData.specificInstructions === 'No'}
                    onChange={handleChange}
                    
                  />
                  No
                </label>
              </div>
            </label>

            {formData.specificInstructions === 'Yes' && (
              <label>
                <h3>Notes for the Movers:</h3>
                <textarea
                  name="moverNotes"
                  value={formData.moverNotes || ''}
                  onChange={handleChange}
                  placeholder="Provide any specific notes for the movers"
                />
              </label>
            )}
          </div>
          <div className="destination-nav">
            <NavigationArrows
                    onPrevClick={handleBack}
                    onNextClick={handleNext}
                    prevLabel={'Previous'}
                    nextLabel={"Review and Submit"} // Update button text based on submitting status
            />
        </div>
        </form>

        {showPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <h3>What Time Should Movers Arrive?</h3>
              <div className="time-slots">
                {timeSlots.map((slot, index) => (
                  <label key={index} className="time-slot">
                    <input
                      type="radio"
                      name="timeSlot"
                      value={slot}
                      onChange={handleTimeSlotChange}
                      checked={formData.preferredTime && formData.preferredTime.includes(slot)}
                      disabled={!selectedDate} // Disable if no date is selected
                    />
                    <span>{slot}</span>
                  </label>
                ))}
              </div>
              <div className="buttons">
                <button className = "cancel-sched" onClick={handlePopupClose}>Cancel</button>
                <button
                  className = "save-sched"
                  onClick={handlePopupClose}
                  disabled={!formData.preferredTime} // Disable save if no time is selected
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Destination;