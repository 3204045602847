import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../layout';
import Review from '../../assets/lexos_imgs/review-clipboard.png'
import { AddressContext } from '../homeOwner/context/address_context';
import { FormContext } from '../homeOwner/context/form_context';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/homeOwner/final_review.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  saveOriginInfo,
  saveDestinationInfo,
  saveMoveDetails,
  saveVirtualWalkthrough,
  saveInventory,
  getUserInfo,
} from '../../firebase/firestoreUtil';// Import Firestore utilities
import { EditButton } from './inventory/inventory_styles';

const FinalReview = () => {
  const {address } = useContext(AddressContext);
  const { formData, accessOptions } = useContext(FormContext);
  const inventoryItems = formData.inventoryItems || {};
  const location = useLocation();
  const storage = getStorage();
  const navigate = useNavigate();

  /* Helper variables  */
  // Before the return statement, define helper variables
  const hasFloors = formData.floors !== undefined && formData.floors !== null && formData.floors !== '';
  const hasBuildingFloor = formData.buildingFloor !== undefined && formData.buildingFloor !== null && formData.buildingFloor !== '';
  const displayFloors = hasFloors && formData.exactFloors > 0 ? formData.exactFloors : hasFloors ? formData.floors : hasBuildingFloor ? 1 : 'N/A';
  

  const [submitting, setSubmitting] = useState(false);

  // Function to parse the address
  const parseAddress = (addressString) => {
    const parts = addressString.split(',').map(part => part.trim());
    const line = parts[0] || '';
    const city = parts[1] || '';
    const stateZip = parts[2] ? parts[2].split(' ') : [];
    const state = stateZip[0] || '';
    const zip = stateZip[1] || '';
    return { line, city, state, zip };
  };

  const uploadImages = async (photos, userId, roomId) => {
    const urls = [];
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      const storageRef = ref(storage, `virtualWalkthroughs/${userId}/${roomId}/${photo.name}`);
      try {
        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, photo);
        // Get the download URL
        const url = await getDownloadURL(storageRef);
        urls.push(url);
      } catch (error) {
        console.error(`Error uploading image ${photo.name}:`, error);
        // Handle the error as needed
      }
    }
    return urls;
  };

  const handleEdit = (section) => {
    if (section === 'property') {
      navigate('/property-info', { state: { step: 'origin', formData, fromReview: true} });
    } else if (section === 'destinations') {
      navigate('/property-info', { state: { step: 'destinations', formData, fromReview: true } });
    }
    else if (section === 'inventory') {
      navigate('/generate-inventory', { 
        state: { 
          ...location.state, 
          fromReview: true,
          inventoryItems: formData.inventoryItems 
        } 
      });
    }
    else if (section === 'move') {
      navigate('/destination', { state: { fromReview: true } });
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      const userInfo = await getUserInfo();
      const username = userInfo ? userInfo.username : '';
      const user = getAuth().currentUser;
      // console.log("Authenticated user:", user);
      const userId = user.uid;

      /** ---- Save Origin Information ---- **/
      const originAddress = address;
      const originParsedAddress = parseAddress(originAddress);
      const originAccessOptions = accessOptions[originAddress] || {};

      const originAccessArray = Object.entries(originAccessOptions).map(([key, value]) => ({
        type: key,
        value: value,
      }));

      const originInfoData = {
        access: originAccessArray,
        city: originParsedAddress.city,
        level: formData.buildingFloor || '',
        line: originParsedAddress.line,
        state: originParsedAddress.state,
        zip: originParsedAddress.zip,
        moveType: formData.moveType,
        numBedrooms: formData.exactBedrooms || formData.bedrooms || '',
        numFloors: formData.exactFloors || formData.floors || '',
        squareFeet: formData.squareFootage || '',
        residenceType: formData.residenceType || '',
      };

      await saveOriginInfo(originInfoData);

      /** ---- Save Destination Information ---- **/
      if (formData.destinations && formData.destinations.length > 0) {
        for (let i = 0; i < formData.destinations.length; i++) {
          const destAddress = formData.destinations[i];
          const destParsedAddress = parseAddress(destAddress);
          const destAccessOptions = accessOptions[formData.abbreviatedDestinations[i]] || {};
          const destAccessArray = Object.entries(destAccessOptions).map(([key, value]) => ({
            type: key,
            value: value,
          }));

          const destinationInfoData = {
            access: destAccessArray,
            city: destParsedAddress.city,
            line: destParsedAddress.line,
            state: destParsedAddress.state,
            zip: destParsedAddress.zip,
            storageUnit: false, // As per your note
          };

          // Generate destination document ID
          const destinationDocId = `${username}-dest-${i + 1}`;

          await saveDestinationInfo(destinationInfoData, destinationDocId);
        }
      }

      /** ---- Save Move Details ---- **/
      const moveDetailsData = {
        dom: formData.selectedDate || '',
        time: formData.preferredTime || '',
        estimate: null, // Will be updated by moving company
        present: formData.presentDuringMove === 'Yes' ? true : false,
        specificInstructions: formData.specificInstructions || '',
        accessInstructions: formData.accessInstructions || '',
      };

      await saveMoveDetails(moveDetailsData);

      /** ---- Save Virtual Walkthrough ---- **/
      let roomImages = [];
      if (formData.photos && formData.photos.length > 0) {
        // Upload images and get URLs
        const photoUrls = await uploadImages(formData.photos, userId);
        // Build roomImages array with URLs
        roomImages = photoUrls.map((url, index) => ({
          [`room ${index + 1}`]: url,
        }));
      }

      const virtualWalkthroughData = {
        created_at: new Date(),
        roomImages: roomImages,
      };

      await saveVirtualWalkthrough(virtualWalkthroughData);

      /** ---- Save Inventory ---- **/
      const roomsData = {};
      Object.keys(inventoryItems).forEach((roomName) => {
        const itemsArray = inventoryItems[roomName].map((item) => ({
          destination: item.destination ? item.destination : formData.destinations[0], // Map to first destination
          highValue: item.isHighValue ? item.estimatedCost : 0,
          item: item.name || '',
          services: {
            moving: item.isMoved || false,
            packing: item.isPacked || false,
            unpacking: item.isUnpacked || false,
          },
        }));
        roomsData[roomName] = {
          items: itemsArray,
        };
      });

      const inventoryData = {
        rooms: roomsData,
      };

      await saveInventory(inventoryData);

      console.log("All data saved successfully!");

      // Navigate to a success or final message page
      navigate('/final-message');
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="final-review-container">
        <h1>Review Your Move Information</h1>
        <img src={Review} alt="Review Clipboard" className="review-image" />
        <p>Please review the details below and make sure everything is correct. You can edit any section if needed.</p>

        {/* Property Information Section */}
        <div className="review-section">
          <h2>Origin Information</h2>
          <div className="review-item">
                <span><strong>Address:</strong> {address || 'N/A'}</span>
            </div>
          <div className="review-item">
            <span><strong>Moving Services:</strong> {formData.moveType || 'N/A'}</span>
          </div>
          <div className="review-item">
            <span><strong>Residence Type:</strong> {formData.residenceType || 'N/A'}</span>
          </div>
          <div className="review-item">
            <span><strong>Floors:</strong> {displayFloors}</span>
          </div>
          {/* Conditionally render the Level if floors are not provided but buildingFloor is */}
          {hasBuildingFloor && (formData.residenceType === 'Apartment' || formData.residenceType === 'Condo') &&(
            <div className="review-item">
              <span><strong>Building Floor:</strong> {formData.buildingFloor}</span>
            </div>
          )}
          <div className="review-item">
            <span>
              <strong>Bedrooms:</strong>{' '}
              {Number(formData.exactBedrooms) > 0
                ? formData.exactBedrooms
                : formData.bedrooms || 'N/A'}
            </span>
          </div>
          <div className="review-item">
          <span><strong>Square Footage:</strong> {formData.squareFootage || 'N/A'}</span>
          </div>

          <div className="review-item">
              <span><strong>Access Restrictions:</strong> {
                  accessOptions && accessOptions[formData.currentAddress] ? (
                  (() => {
                      const activeOptions = [];
                      if (accessOptions[formData.currentAddress].elevator) activeOptions.push('Elevator');
                      if (accessOptions[formData.currentAddress].stairs) activeOptions.push('Stairs');
                      if (accessOptions[formData.currentAddress].driveway) activeOptions.push('Driveway');
                      if (accessOptions[formData.currentAddress].streetParking) activeOptions.push('Street Parking');
                      if (accessOptions[formData.currentAddress].loadingDock) activeOptions.push('Loading Dock');
                      if (accessOptions[formData.currentAddress].gatedEntrance) activeOptions.push('Gated Entrance');

                      return activeOptions.length > 0 ? activeOptions.join(', ') : 'None';
                  })()
                  ) : 'N/A'
              }</span>
          </div>

        {/* More items here ... */}
        <EditButton onClick={() => handleEdit('property')}>
          <div className="edit-icon">✎</div>
          <div className="edit-text">{'Edit Origin Info'}</div>
        </EditButton>
      </div>
      {/* Inventory Section */}
      <div className="review-section">
      <h2>Inventory</h2>
      <Accordion >
        {Object.keys(inventoryItems).map((room, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{room}</Accordion.Header>
            <Accordion.Body>
              {inventoryItems[room].map((item, itemIndex) => (
                <div key={itemIndex}>
                  <strong></strong> {item.name}
                  <br />
                  {item.details.map((detail, detailIndex) => (
                    <div key={detailIndex} style={{ paddingLeft: '20px' }}>
                      - {detail}
                    </div>
                  ))}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <EditButton className= "inventory-review" onClick={() => handleEdit('inventory')}>
          <div className="edit-icon">✎</div>
          <div className="edit-text">{'Edit Inventory'}</div>
        </EditButton>
    </div>

        {/* Destination Information Section */}

        <div className="review-section">
        <h2>Destination Information</h2>
        {formData.destinations && formData.destinations.length > 0 ? (
            formData.destinations.map((destination, index) => (
            <div key={index}>
                <div className="review-item" >
                <span>
                    <strong>
                    {formData.destinations.length > 1
                        ? `Destination Address #${index + 1}`
                        : 'Destination Address'}:
                    </strong>{' '}
                    {destination || 'N/A'}
                </span>
                </div>

                {/* Add a tabbed effect for Access Restrictions */}
                <div className="review-item" style={{ paddingLeft: '40px' }}>
                <span><strong>Access Restrictions:</strong> </span>
                {accessOptions && accessOptions[formData.abbreviatedDestinations[index]] ? (
                    (() => {
                    const accessArray = [];
                    const access = accessOptions[formData.abbreviatedDestinations[index]]; // Get access options for the destination address

                    if (access.elevator) accessArray.push('Elevator');
                    if (access.stairs) accessArray.push('Stairs');
                    if (access.driveway) accessArray.push('Driveway');
                    if (access.streetParking) accessArray.push('Street Parking');
                    if (access.loadingDock) accessArray.push('Loading Dock');
                    if (access.gatedEntrance) accessArray.push('Gated Entrance');

                    return accessArray.length > 0 ? accessArray.join(', ') : 'None';
                    })()
                ) : (
                    'N/A'
                )}
                </div>
            </div>
            ))
        ) : (
            <div className="review-item">
            <span>
                <strong>Destination Address:</strong> N/A
            </span>
            </div>
        )}
              {/* Edit button */}
          <EditButton onClick={() => handleEdit('destinations')}>
          <div className="edit-icon">✎</div>
          <div className="edit-text">{'Edit Destination Info'}</div>
        </EditButton>
        </div>
        <div className= "review-section">
          <h2>Move Information</h2>
          <div className="review-item">
              <span><strong>Move Date: </strong> {[formData.selectedDate] || 'N/A'}</span>
          </div>
          <div className="review-item">
              <span><strong>Preferred Time: </strong> {[formData.preferredTime] || 'N/A'}</span>
          </div>
          <div className="review-item">
              <span><strong>Present During Move:</strong> {formData.presentDuringMove ? 'Yes' : 'No'}</span>
          </div>
          <div className="review-item">
              <span><strong>Access Instructions:</strong> {formData.accessInstructions || 'N/A'}</span>
          </div>
          <div className="review-item">
              <span><strong>Specific Instructions:</strong> {formData.moverNotes || 'N/A'}</span>
          </div>

        {/* Edit button */}
        <EditButton onClick={() => handleEdit('move')}>
            <div className="edit-icon">✎</div>
            <div className="edit-text">{'Edit Move Info'}</div>
          </EditButton>
        </div>
            {/* Submit Button */}
            <button className="submit-button" onClick={handleSubmit} disabled={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
            </button>
        </div>
            </Layout>
        );
        };

export default FinalReview;
