import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { AddressContext } from './address_context';

const FormContext = React.createContext();

const abbreviateAddress = (fullAddress) => {
    const parts = fullAddress.split(',');
    return parts.length > 1 ? `${parts[0]}, ${parts[1].trim()}` : fullAddress;
};

const FormProvider = ({ children }) => {
    const { address } = useContext(AddressContext);
    const [formData, setFormData] = useState({
      currentAddress: "Current Address",
      destinations: [],
      abbreviatedDestinations: [],
      moveType: '',
      residenceType: '',
      bedrooms: '',
      squareFootage: '',
      floors: '',
      hasMultipleFloors: '',
      buildingFloor: '',
      exactFloors: '',
      exactBedrooms: '',
      selectedDate: '',
      preferredTime: '',
      presentDuringMove: '',
      accessInstructions: '',
      specificInstructions: '',
      moverNotes: '',
      inventoryItems: [],
      photos: [],
    });

    const [accessOptions, setAccessOptions] = useState({});

    const updateAccessOptions = useCallback((address) => {
      setAccessOptions(prevOptions => {
        if (prevOptions[address]) return prevOptions; // Return early if options already exist
        return {
          ...prevOptions,
          [address]: {
            elevator: false,
            stairs: false,
            driveway: false,
            streetParking: false,
            loadingDock: false,
            gatedEntrance: false,
            noneOfTheAbove: false,
          }
        };
      });
    }, []);

    useEffect(() => {
      if (address) {
        const abbreviatedAddress = abbreviateAddress(address);
        setFormData(prevData => ({
          ...prevData,
          currentAddress: abbreviatedAddress,
        }));
        updateAccessOptions(abbreviatedAddress);
      }
    }, [address, updateAccessOptions]);

    useEffect(() => {
      updateAccessOptions(formData.currentAddress);
      formData.abbreviatedDestinations.forEach(updateAccessOptions);
    }, [formData.currentAddress, formData.abbreviatedDestinations, updateAccessOptions]);

    const setFormDataCallback = useCallback((newData) => {
      setFormData(prevData => {
        const updatedData = typeof newData === 'function' ? newData(prevData) : newData;
        return { ...prevData, ...updatedData };
      });
    }, []);

    const setAccessOptionsCallback = useCallback((newOptions) => {
      setAccessOptions(prevOptions => {
        const updatedOptions = typeof newOptions === 'function' ? newOptions(prevOptions) : newOptions;
        return { ...prevOptions, ...updatedOptions };
      });
    }, []);

    const contextValue = useMemo(() => ({
      formData,
      setFormData: setFormDataCallback,
      accessOptions,
      setAccessOptions: setAccessOptionsCallback,
      updateAccessOptions,
    }), [formData, accessOptions, setFormDataCallback, setAccessOptionsCallback, updateAccessOptions]);

    return (
      <FormContext.Provider value={contextValue}>
        {children}
      </FormContext.Provider>
    );
};

export { FormContext, FormProvider };