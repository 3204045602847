import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: 42.3601, // Latitude for Boston, MA
  lng: -71.0589, // Longitude for Boston, MA
};

const Map = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyACu1ggrE0coSnenfLAX0R-9p3r8dBV0lU">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {/* Add markers here later */}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;