import React from 'react';
import '../../styles/homeOwner/offer_modal.css';
import { MdOutlineLocalOffer, MdOutlineHandshake, MdAccessTimeFilled   } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
import { IoShieldCheckmark } from "react-icons/io5";

const OfferModal = ({ show, onClose, offerDetails }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="offer-modal">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>Offer Breakdown</h2>
        <h5>{offerDetails}</h5>
        <div className="estimate-section">
          <MdOutlineLocalOffer className="offer-icon" />
          <p>Estimate: $2340.78</p>
        </div>
        <div className = "offer-type">
          <MdOutlineHandshake className="type-icon" />
          <p>Offer Type: Non-Binding</p>
        </div>
        <div className = "insurance">
          <IoShieldCheckmark className="insurance-icon" />
          <p>Insurance: Provided </p>
        </div>
        <div className = "time">
          <MdAccessTimeFilled className="time-icon" />
          <p>Estimated Time: 7 hours</p>
        </div>
        <div className="modal-actions">
          <button className="btn-close-text" onClick={onClose}>
            Close
          </button>
          <button className="btn accept-offer-btn">Accept Offer</button>
        </div>
      </div>
    </div>
  );
};

export default OfferModal;