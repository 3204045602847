// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {getStorage} from 'firebase/storage';
import { getFirestore } from "firebase/firestore";
//  Re-enable these imports if you need them
//  import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUIjYbNmTlZP3j09_z-qPjGJaQqZ4vDq4",
  authDomain: "lexos-inc.firebaseapp.com",
  projectId: "lexos-inc",
  storageBucket: "lexos-inc.appspot.com",
  messagingSenderId: "994049577124",
  appId: "1:994049577124:web:8a8055b7b42091266c5631",
  measurementId: "G-EW9NMTH6HB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app);


//  Re-enable this if you need it
// const storage = app.storage();
// const analytics = getAnalytics(app);

export { firebaseConfig , auth, provider, storage , db};
