import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const LoadingCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${rotate} 1s linear infinite;
  margin: 0 auto 1rem;
`;

const Title = styled.h3`
  color: var(--primary-color);
  margin-bottom: 1rem;
  white-space: nowrap;
`;

const SubTitle = styled.p`
  font-style: italic;
  color: var(--dark-gray-color);
  margin-bottom: 1rem;
`;


const Description = styled.p`
  color: #666;
  margin-bottom: 0.5rem;
  height: 24px; // Fixed height to prevent layout shift
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 1rem;
`;

const ProgressBarFill = styled.div`
  width: ${props => props.progress}%;
  height: 10px;
  background-color: #8b50f7;
  border-radius: 4px;
  transition: width 0.5s ease-out;
`;

const ProcessWalkthrough = ({ isVisible, onComplete, analysisResult, photosByRoom }) => {
  const [progress, setProgress] = useState(0);
  const [statusText, setStatusText] = useState('Initializing...');

  useEffect(() => {
    if (isVisible) {
      const firstRoomPhotos = photosByRoom ? Object.values(photosByRoom)[0] : [];
      const numPhotos = firstRoomPhotos.length;
      console.log('Number of photos:', numPhotos);

      // Determine the total processing time based on the number of photos
      const totalProcessingTime = numPhotos * 7000; // 7 seconds per photo
      console.log("Total processing time:", totalProcessingTime);

      // Distribute the time proportionally across the stages
      const stages = [
        { text: 'Initializing analysis...', duration: totalProcessingTime * 0.1, progressEnd: 10 },
        { text: 'Analyzing room photos...', duration: totalProcessingTime * 0.4, progressEnd: 40 },
        { text: 'Generating inventory...', duration: totalProcessingTime * 0.3, progressEnd: 75 },
        { text: 'Finalizing results...', duration: totalProcessingTime * 0.15, progressEnd: 90 },
        { text: 'Wrapping up...', duration: totalProcessingTime * 0.05, progressEnd: 100 },
      ];

      let totalDuration = 0;

      stages.forEach((stage, index) => {
        setTimeout(() => {
          if (!analysisResult) {
            setStatusText(stage.text);
            setProgress(stage.progressEnd);
          }
        }, totalDuration);

        totalDuration += stage.duration;
      });

      // Check if analysis completes, but delay navigation until all stages are visually complete
      const completeAnalysisCheck = () => {
        if (analysisResult) {
          setStatusText('Finalizing results...');
          setProgress(100);
          setTimeout(() => {
            if (onComplete) onComplete();
          }, totalDuration); // Wait until all visual stages are done
        }
      };

      const intervalId = setInterval(completeAnalysisCheck, 500);

      return () => clearInterval(intervalId);
    }
  }, [isVisible, onComplete, analysisResult, photosByRoom]);

  if (!isVisible) return null;

  return (
    <Overlay>
      <LoadingCard>
        <Title>Processing Your Walkthrough</Title>
        <SubTitle>Please do not refresh your screen</SubTitle>
        <Spinner />
        <Description>{statusText}</Description>
        <ProgressBarContainer>
          <ProgressBarFill progress={progress} />
        </ProgressBarContainer>
      </LoadingCard>
    </Overlay>
  );
};

export default ProcessWalkthrough;
