import React , { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout.js';
import '../../styles/movingCos/view_listing.css';
import videoBedroomSrc from '../../assets/inventory_vids/inventory_bedroom1.mp4';
import videoKitchenSrc from '../../assets/inventory_vids/Inventory_kitchen1.mp4';
import videoLivingRoomSrc from '../../assets/inventory_vids/Inventory_LR1.mp4';

const ViewListing = () => {
    const [activeTab, setActiveTab] = useState('inventory');
  
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };

    const navigate = useNavigate();

    const VideoComponent = ({ src }) => (
        <video width="320" height="240" className="video-card" controls>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
      
      const VideosSection = () => {
        return (
          <div className="videos">
            <VideoComponent src={videoBedroomSrc} />
            <VideoComponent src={videoKitchenSrc} />
            <VideoComponent src={videoLivingRoomSrc} />
          </div>
        );
      };
    const inventoryContent = `
    <h2>Bedroom:</h2>
    <ul>
      <li>Queen Size Bed
        <ul>
          <li>Queen Size Bed Frame
            <ul>
              <li>Weight: 120 lbs</li>
              <li>Dimensions: 60" W 14" H 80" L</li>
              <li><strong><em>Movers: 2-3</em></strong></li>
            </ul>
          </li>
          <li>Queen Size Mattress
            <ul>
              <li>Weight: 60 lbs</li>
              <li>Dimensions: 60" W 10" H 80" L</li>
              <li><strong><em>Movers: 2</em></strong></li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Blue Swivel Chair
        <ul>
          <li>Weight: 120 lbs</li>
          <li>Dimensions: 32" W 24" H 24" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>Desk
        <ul>
          <li>Weight: 60 lbs</li>
          <li>Dimensions: 60" W 40" H 16" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>Two Nightstands
        <ul>
          <li>Weight: 30 lbs</li>
          <li>Dimensions: 15" W 24" H 17" L</li>
          <li><strong><em>Movers: 1 per nightstand</em></strong></li>
        </ul>
      </li>
      <li>Dyson Fan
        <ul>
          <li>Weight: 12 lbs</li>
          <li>Dimensions: 8" W 50" H 8" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>Two Nightstand Lamps
        <ul>
          <li>Weight: 12 lbs</li>
          <li>Dimensions: 12" W 30" H 12" L</li>
          <li><strong><em>Movers: 1 per lamp</em></strong></li>
        </ul>
      </li>
      <li>Desk Chair
        <ul>
          <li>Weight: 10 lbs</li>
          <li>Dimensions: 16" W 18" H 14" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>Desk Lamp
        <ul>
          <li>Weight: 6 lbs</li>
          <li>Dimensions: 6" W 15" H 6" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
    </ul>
    <h2>Kitchen:</h2>
    <ul>
      <li>Convection Microwave Oven
        <ul>
          <li>Weight: 30 lbs</li>
          <li>Dimensions: 15" W 10" H 12" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>Cooking Utensils in a Marble Holder
        <ul>
          <li>Weight: 10 lbs</li>
          <li>Dimensions: 8" W 8" H 8" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>Coffee Machine
        <ul>
          <li>Weight: 10 lbs</li>
          <li>Dimensions: 6" W 8" H 10" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>Pots and Pans
        <ul>
          <li>Quantity: 10 (estimate)</li>
        </ul>
      </li>
      <li>Glasses and Cups
        <ul>
          <li>Quantity: 58 (estimate)</li>
        </ul>
      </li>
    </ul>
    <h2>Living Room:</h2>
    <ul>
      <li>Couch
        <ul>
          <li>Weight: 250 lbs</li>
          <li>Dimensions: 36" W 18" H 32" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>TV Stand
        <ul>
          <li>Weight: 150 lbs</li>
          <li>Dimensions: 80" W 14" H 10" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>Coffee Table
        <ul>
          <li>Weight: 140 lbs</li>
          <li>Dimensions: 30" W 18" H 32" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>2 White Chairs
        <ul>
          <li>Weight: 120 lbs</li>
          <li>Dimensions: 32" W 24" H 24" L</li>
          <li><strong><em>Movers: 2 per chair</em></strong></li>
        </ul>
      </li>
      <li>2 Grey Swivel Chairs
        <ul>
          <li>Weight: 120 lbs</li>
          <li>Dimensions: 32" W 24" H 24" L</li>
          <li><strong><em>Movers: 2 per chair</em></strong></li>
        </ul>
      </li>
      <li>2 Floral Arrangements
        <ul>
          <li>Weight: 100 lbs</li>
          <li>Dimensions: 16" W 36" H 18" L</li>
          <li><strong><em>Movers: 2 per floral arrangement</em></strong></li>
        </ul>
      </li>
      <li>Book Shelf
        <ul>
          <li>Weight: 60 lbs</li>
          <li>Dimensions: 12" W 84" H 8" L</li>
          <li><strong><em>Movers: 2</em></strong></li>
          <li>Books
            <ul>
              <li>Quantity: 25</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Two Coffee Stands
        <ul>
          <li>Weight: 40 lbs</li>
          <li>Dimensions: 10" W 10" H 14" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
          <li>Books
            <ul>
              <li>Quantity: 12</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>Artistic Sculpture (#3 shown in the video)
        <ul>
          <li>Weight: 40 lbs</li>
          <li>Dimensions: 8" W 24" H 6" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>TV
        <ul>
          <li>Weight: 30 lbs</li>
          <li>Dimensions: 40" wide screen</li>
          <li><strong><em>Movers: 2</em></strong></li>
        </ul>
      </li>
      <li>2 Mini Tables
        <ul>
          <li>Weight: 25 lbs</li>
          <li>Dimensions: 12" W 16" H 12" L</li>
          <li><strong><em>Movers: 1 per table</em></strong></li>
        </ul>
      </li>
      <li>Two Lengthy Lights
        <ul>
          <li>Weight: 20 lbs</li>
          <li>Dimensions: 6" W 84" H 6" L</li>
          <li><strong><em>Movers: 1 per light</em></strong></li>
        </ul>
      </li>
      <li>Artistic Sculpture (#2 shown in the video)
        <ul>
          <li>Weight: 20 lbs</li>
          <li>Dimensions: 12" W 18" H 6" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>3 Fine Arts
        <ul>
          <li>Weight: 15 lbs</li>
          <li>Dimensions: 36" W 48" H 2" L</li>
          <li><strong><em>Movers: 1 per item</em></strong></li>
        </ul>
      </li>
      <li>Dog Bed
        <ul>
          <li>Weight: 15 lbs</li>
          <li>Dimensions: 20" W 6" H 8" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
      <li>Two Sonos Speakers
        <ul>
          <li>Weight: 10 lbs</li>
          <li>Dimensions: 6" W 6" H 5" L</li>
          <li><strong><em>Movers: 1 per speaker</em></strong></li>
        </ul>
      </li>
      <li>Artistic Sculpture (#1 shown in the video)
        <ul>
          <li>Weight: 10 lbs</li>
          <li>Dimensions: 6" W 10" H 5" L</li>
          <li><strong><em>Movers: 1</em></strong></li>
        </ul>
      </li>
    </ul>
    <p><strong>Recommended Movers: 4</strong></p>
    `;

  return (
    <Layout>
    <div className="viewListing-container">
        <h1 className="listing-title">Listing Details</h1>
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'inventory' ? 'active' : ''}`}
          onClick={() => handleTabChange('inventory')}
        >
          Inventory
        </button>
        <button
          className={`tab-button ${activeTab === 'videos' ? 'active' : ''}`}
          onClick={() => handleTabChange('videos')}
        >
          Videos
        </button>
      </div>
      <div className="tab-content">
      {activeTab === 'inventory' && (
          <div className="inventory" dangerouslySetInnerHTML={{ __html: inventoryContent }} />
        )}
        {activeTab === 'videos' && <VideosSection />}
      </div>
      <div className="listingView-button-navigation">
        <button className="btn btn-danger" onClick={() => {navigate('/listings')}}>Back</button>
        <button className="submit-bid-button" onClick={() => {navigate('/bid-form');}}>Submit a Bid</button>
      </div>
    </div>
    </Layout>
  );

};

export default ViewListing;