import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CalendarContainer = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  font-family: Arial, sans-serif;
`;

const CalendarHeader = styled.div`
  background-color: #8b50f7;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #f0f0f0;
  color: var(--dark-gray-color);
  padding: 10px;
`;

const CalendarCell = styled.div`
  height: 60px;
  padding: 5px;
  background-color: white;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #f0f0f0;
  }
  ${props => props.isSelected && `
    background-color: #8b50f7;
    color: white;
  `}
  ${props => props.isToday && `
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  `}
  ${props => props.isDisabled && `
    background-color: #e0e0e0;
    color: #b0b0b0;
    pointer-events: none;
    cursor: default;
  `}
`;

const DayNumber = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
  color: inherit;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  ${props => props.disabled && `
    color: #999;
    pointer-events: none;
  `}
`;

const CustomCalendar = ({ onDateSelect, initialSelectedDate }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparisons
  const [currentDate, setCurrentDate] = useState(initialSelectedDate ? new Date(initialSelectedDate) : new Date());
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate ? new Date(initialSelectedDate) : null);

  useEffect(() => {
    if (initialSelectedDate) {
      setSelectedDate(new Date(initialSelectedDate));
    }
  }, [initialSelectedDate]);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const handleDateClick = (e, day) => {
    e.preventDefault();
    const selected = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    selected.setHours(0, 0, 0, 0);
    if (selected >= today) { // Changed from > to >= to include today
      setSelectedDate(selected);
      onDateSelect(selected);
    }
  };

  const nextMonth = (e) => {
    e.preventDefault();
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const prevMonth = (e) => {
    e.preventDefault();
    if (currentDate > today) {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    }
  };

  const renderCells = () => {
    const cells = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push(<CalendarCell key={`empty-${i}`} />);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      date.setHours(0, 0, 0, 0);
      const isToday = date.getTime() === today.getTime();
      const isSelected = selectedDate && date.getTime() === selectedDate.getTime();
      const isDisabled = date < today;

      cells.push(
        <CalendarCell 
          key={day} 
          onClick={isDisabled ? null : (e) => handleDateClick(e, day)}
          isToday={isToday}
          isSelected={isSelected}
          isDisabled={isDisabled}
        >
          <DayNumber>{day}</DayNumber>
        </CalendarCell>
      );
    }
    return cells;
  };

  return (
    <CalendarContainer>
      <CalendarHeader>
        <ArrowButton 
          onClick={prevMonth}
          disabled={currentDate <= today}
        >«</ArrowButton>
        <span>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
        <ArrowButton onClick={nextMonth}>»</ArrowButton>
      </CalendarHeader>
      <CalendarGrid>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
          <CalendarCell key={day}>{day}</CalendarCell>
        ))}
        {renderCells()}
      </CalendarGrid>
    </CalendarContainer>
  );
};

export default CustomCalendar;
