import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import '../../styles/bootstrap_imported.css'; // This is the bootstrap css 
import '../../styles/layout.css'; 
import './landing_page.css';  
import Layout from '../layout'; // Adjust the path as needed
import { AddressContext } from '../homeOwner/context/address_context';
import moving_truck from '../../assets/lexos_imgs/welcome_movers.png';
import search_icon from '../../assets/lexos_imgs/search-magnifier.png';
import error_icon from '../../assets/lexos_imgs/search-magnifier.png'; // Create an error icon image
/* Django */


const LandingPage = () => {
  const { setAddress } = useContext(AddressContext);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Activate Bootstrap scrollspy on the main nav element
    const bootstrap = require('bootstrap');
    const mainNav = document.body.querySelector('#mainNav');
    if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
        target: '#mainNav',
        rootMargin: '0px 0px -40%',
      });
    }

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(
      document.querySelectorAll('#navbarResponsive .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
          navbarToggler.click();
        }
      });
    });
  }, []);

  const handlePlaceSelected = (place) => {
    if (place && place.formatted_address) {
      setAddress(place.formatted_address);
      setIsValidAddress(true);
      setShowError(false);
    } else {
      setIsValidAddress(false);
    }
  };

  const handleSearchClick = () => {
    if (isValidAddress) {
      navigate('/property-info');
    } else {
      setShowError(true);
    }
  };

  return (
    <Layout>
      {/* Body */}
      <body className="landing main-content" style={{ flex: '1 0 auto' }}>
        {/* Wrap the body in a form to move everything down */}
        <form className="landing-form">
          <div className="container px-4 text-center">
            <h1 className="fw-bolder header-title">
              Welcome<span role="img" aria-label="waving hand">👋</span>
            </h1>
            <p className="lead header-description">
              Create your AI-assisted virtual inventory and get quotes from moving companies
            </p>
            <div className="d-flex flex-column align-items-center">
              <img
                src={moving_truck}
                alt="Moving Truck"
                className="img-fluid"
                style={{ maxHeight: '420px', margin: '-30px auto 5px' }}
              />
              <div className="home-search-bar">
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  onPlaceSelected={handlePlaceSelected}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'us' },
                  }}
                  placeholder="Where are you moving from?"
                  className={`form-control ${showError ? 'is-invalid' : ''}`}
                />
                
                {/* Render two different buttons based on error or normal state */}
                {showError ? (
                  <button
                    className="btn btn-search-error"
                    type="button"
                    onClick={handleSearchClick}
                  >
                    <img src={error_icon} alt="Error Search Icon" className="search-icon" />
                  </button>
                ) : (
                  <button
                    className="btn btn-search"
                    type="button"
                    onClick={handleSearchClick}
                  >
                    <img src={search_icon} alt="Search Icon" className="search-icon" />
                  </button>
                )}

                {/* Error feedback message */}
                {showError && (
                  <div className="invalid-feedback">Please select a valid address.</div>
                )}
              </div>
            </div>
          </div>
        </form>
      </body>
    </Layout>
  );
};

export default LandingPage;
