import React, { createContext, useContext, useState } from 'react';

const OpenAIContext = createContext();

export const useOpenAI = () => useContext(OpenAIContext);

export const OpenAIProvider = ({ children }) => {
  const [openAIGenerator, setOpenAIGenerator] = useState(null);

  const value = {
    openAIGenerator,
    setOpenAIGenerator,
  };

  return (
    <OpenAIContext.Provider value={value}>
      {children}
    </OpenAIContext.Provider>
  );
};