import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../styles/layout.css'; // Adjust the path as needed
import white_logo from '../assets/lexos_imgs/full_logo_all_white_txt.png';
import { createGlobalStyle } from 'styled-components';
import fastDelivery from '../assets/lexos_imgs/fast-delivery.png';
import UserAccountDropdown from './homeOwner/register/user_dropdown';
import { auth } from '../firebase/fireconfig';
import { onAuthStateChanged } from 'firebase/auth';

const Layout = ({ children }) => {
  const [userName, setUserName] = useState(null);  // State to hold the user's name
  const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
  }
  `;
  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
    // Hook to check the user's authentication state and get their info
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // Assuming the user's displayName is set in Firebase
          const firstName = user.displayName?.split(' ')[0];  // Get the first name
          setUserName(firstName || '');  // Fallback to 'User' if no displayName is available
        } else {
          setUserName(null);  // Reset to null if no user is logged in
        }
      });
  
      // Cleanup the subscription on component unmount
      return () => unsubscribe();
    }, []);
  return (
    <div id="page-top" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      
      <GlobalStyle />
      {/* Navigation */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="container px-4">
          <Link className="navbar-brand" to="/">
            <img src={white_logo} alt="lexos-white" style={{ height: '40px' }} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">

            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                
                <Link className="nav-link text-white become-lexpro" to="/become-lexpro">
                  <h3 className="lexText">Become a LexPro</h3>
                  <img className="truck" src={fastDelivery} alt="truck" />
                  <div className="street"></div>
                </Link>
              </li>
              {/* <li className="nav-item">
                <UserAccountDropdown userName={userName} />
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      {/* Body */}
      <main className="main-content">
        {children}
      </main>
      {/* Footer */}
      <footer className="py-5" style={{ flexShrink: '0' }}>
        <div className="container px-4">
          <div className="row">
            <div className="col-md-6 text-white text-start">
              <p className="m-0">Copyright &copy; Lexos, Inc. 2024</p>
            </div>
            <div className="col-md-6 text-white text-end">
              <ul className="list-inline mb-0">
                <li className="list-inline-item"><Link className="nav-link text-white" to="#about">About</Link></li>
                <li className="list-inline-item"><Link className="nav-link text-white" to="#services">Services</Link></li>
                <li className="list-inline-item"><Link className="nav-link text-white" to="#contact">Contact</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
